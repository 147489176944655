import React from "react";
import {Link} from "react-router-dom";
import {Button, Typography} from "antd";
const {Text} = Typography;

const CreateNewMailingMessage = () => {
    return (
        <>
            <Text
                className={"main-sms-not-connected-title"}
                type={"secondary"}
            >
                СМС рассылок не найдено
            </Text>
            <br/>
            <Button type={"primary"}>
                <Link to={'/system/main-sms/create-mailing'}>Создать</Link>
            </Button>
        </>
    )
};

export default CreateNewMailingMessage