import React from "react";
import DefaultTable from "../components/DefaultTable/DefaultTable";
import deviceForm from "../components/DeviceForm";

const form = {
    name: 'device',
    title: 'Устройства',
    schema: [
        {
            type: 'string',
            name: 'serial',
            label: 'Серийный Номер',
        },
        {
            type: 'selectRemoteCreatable',
            name: 'type',
            label: 'Тип Устройства',
            api: '/deviceType/query',
            createAPI: '/deviceType/create',
            isArray: false,
            dataKeys: {value: '_id', label: 'name'}
        },
        {
            type: 'selectRemoteCreatable',
            name: 'brand',
            label: 'Бренд Устройства',
            api: '/deviceBrand/query',
            createAPI: '/deviceBrand/create',
            isArray: false,
            dataKeys: {value: '_id', label: 'name'}
        },
        {
            type: 'selectRemoteCreatable',
            name: 'model',
            label: 'Модель Устройства',
            api: '/deviceModel/query',
            createAPI: '/deviceModel/create',
            isArray: false,
            dataKeys: {value: '_id', label: 'name'}
        },
    ],
    enrichment: {
        type: {
            label: "name",
            value: "_id"
        },
        brand: {
            label: "name",
            value: "_id"
        },
        model: {
            label: "name",
            value: "_id"
        }
    }
};

const columns = [
    {
        dataIndex: 'serial', title: "Серийный номер", sorter: () => {}
    },
    {
        dataIndex: ['type', 'name'], title: "Тип", sorter: () => {}
    },
    {
        dataIndex: ['brand', 'name'], title: "Бренд", sorter: () => {}
    },
    {
        dataIndex: ['model', 'name'], title: "Модель", sorter: () => {}
    },
];


const DevicePage = () => {
    return (
        <DefaultTable
            schemaId={form.name}
            title={form.title}
            form={form}
            tableColumns={columns}
            formRenderer={deviceForm}
            tableSearchCols={['serial', 'type.name', 'brand.name', 'model.name']}
        />
    )
};

export default DevicePage;