import React from 'react'
import PropTypes from 'prop-types'
import moment from "moment";
import './print.css'
import logo from './logo.jpg'
import stamp from './stamp.png'
import phoneLogo from './phone.gif'

const PrintReception = (props) => {
    const {
        orderId,
        problem,
        createdAt,
        manager,
        client: {
            name,
            phone,
        },
        device: {
            serial,
            brand,
            model,
            password
        },
        printRef,
        addStamp
    } = props;
    return (
        <div style={{display: 'none'}}>
            <div className={"print-reception-wrapper"} ref={printRef}
            >
                <style type="text/css">
                    {`@media print{@page {
                    size: A4 landscape;
                    margin: 5mm;
                 }`}
                </style>
                <div className={"print-reception-firstTable"}>
                    <TableFirstHeader/>
                    <TableFirstBody
                        orderId={orderId}
                        problem={problem}
                        device={{serial, brand, model}}
                        createdAt={createdAt}
                        client={{name, phone}}
                        manager={manager}
                        addStamp={addStamp}
                    />
                </div>
                <div className={"print-reception-separator"}> &nbsp; </div>
                <div className={"print-reception-secondTable"}>
                    <div>Копия сервисного центра</div>
                    <TableSecondBody
                        orderId={orderId}
                        problem={problem}
                        device={{serial, brand, model, password}}
                        createdAt={createdAt}
                        client={{name, phone}}
                        manager={manager}
                    />
                </div>
            </div>
        </div>

    )
};
export default PrintReception;
PrintReception.propTypes = {
    orderId: PropTypes.string,
    problem: PropTypes.string,
    createdAt: PropTypes.string,
    client: PropTypes.shape({
        name: PropTypes.string,
        phone: PropTypes.string,
    }),
    device: PropTypes.shape({
        serial: PropTypes.string,
        brand: PropTypes.string,
        model: PropTypes.string,
    })
};
PrintReception.defaultProps = {
    client: {},
    device: {}
};


const TableFirstHeader = () => {
    return (
        <>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                    <img src={logo} width={230} alt={'logo'}/>
                </div>
                <div style={{display: 'flex', alignItems: 'flex-start', paddingRight: 15}}>
                    <img src={phoneLogo} height={30} alt={'phone'}/>
                    <div className={'print-reception-phoneNumber'}>+7 (925) 707-70-49</div>
                </div>
            </div>
            <div style={{textAlign: 'right', paddingRight: 15}}>
                Садовая-Сухаревская 2/34с1 офис 301
            </div>
            <hr style={{background: 'black', color: 'black'}}/>
        </>
    )
};
const TableFirstBody = ({orderId, client, createdAt, device, problem, manager, addStamp}) => {
    return (
        <div>
            <div style={{display: 'flex'}}>
                <div style={{width: '70%'}}>
                    <table style={{fontSize: 13}}>
                        <tbody>
                        <tr>
                            <th>Договор на ремонт:</th>
                            <td>CV<b>{orderId}</b></td>
                        </tr>
                        <tr>
                            <th>Имя, Фамилия:</th>
                            <td>{client.name}</td>
                        </tr>
                        <tr>
                            <th>Телефон:</th>
                            <td>{client.phone}</td>
                        </tr>
                        <tr>
                            <th>Дата:</th>
                            <td>{moment(createdAt).format('DD-MM-YYYY HH:mm:ss')}</td>
                        </tr>
                        <tr>
                            <th>Устройство:</th>
                            <td>{device.brand} {device.model}</td>
                        </tr>
                        <tr>
                            <th>Серийный номер:</th>
                            <td>{device.serial}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{width: '30%'}}>
                    <table style={{fontSize: 13}}>
                        <thead>
                        <tr>
                            <th>Наличие:</th>

                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                            </td>
                            <td>
                                да нет
                            </td>
                        </tr>
                        <tr>
                            <td>АКБ</td>
                            <td>
                                <div style={{display: 'flex'}}>
                                    <div style={{width: 20, height: 20, border: '1px solid black'}}>

                                    </div>
                                    <div style={{width: 20, height: 20, marginLeft: 2, border: '1px solid black'}}>

                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>БП</td>
                            <td>
                                <div style={{display: 'flex'}}>
                                    <div style={{width: 20, height: 20, border: '1px solid black'}}>

                                    </div>
                                    <div style={{width: 20, height: 20, marginLeft: 2, border: '1px solid black'}}>

                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Царапины</td>
                            <td>
                                <div style={{display: 'flex'}}>
                                    <div style={{width: 20, height: 20, border: '1px solid black'}}>

                                    </div>
                                    <div style={{width: 20, height: 20, marginLeft: 2, border: '1px solid black'}}>

                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Вмятины</td>
                            <td>
                                <div style={{display: 'flex'}}>
                                    <div style={{width: 20, height: 20, border: '1px solid black'}}>

                                    </div>
                                    <div style={{width: 20, height: 20, marginLeft: 2, border: '1px solid black'}}>

                                    </div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div>
                <span style={{fontSize: 13}}><b>Заявленная неисправность: </b></span>
                <span style={{fontSize: 13}}>{problem}</span>
            </div>
            <hr style={{width: '100%', marginBottom: 10, marginTop: 5}}/>
            <Rules client={client} manager={manager} addStamp={addStamp}/>
        </div>
    )
};
const TableSecondBody = ({orderId, client, createdAt, device, problem, manager}) => {
    return (
        <div>
            <div style={{display: 'flex'}}>
                <div style={{width: '70%'}}>
                    <table style={{fontSize: 13}}>
                        <tbody>
                        <tr>
                            <th>Договор на ремонт:</th>
                            <td>CV<b>{orderId}</b></td>
                        </tr>
                        <tr>
                            <th>Имя, Фамилия:</th>
                            <td>{client.name}</td>
                        </tr>
                        <tr>
                            <th>Телефон:</th>
                            <td>{client.phone}</td>
                        </tr>
                        <tr>
                            <th>Дата:</th>
                            <td>{moment(createdAt).format('DD-MM-YYYY HH:mm:ss')}</td>
                        </tr>
                        <tr>
                            <th>Устройство:</th>
                            <td>{device.brand} {device.model}</td>
                        </tr>
                        <tr>
                            <th>Пароль:</th>
                            <td>{device.password}</td>
                        </tr>
                        <tr>
                            <th>Серийный номер:</th>
                            <td>{device.serial}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{width: '30%'}}>
                    <table style={{fontSize: 13}}>
                        <thead>
                        <tr>
                            <th>Наличие:</th>

                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                            </td>
                            <td>
                                да нет
                            </td>
                        </tr>
                        <tr>
                            <td>АКБ</td>
                            <td>
                                <div style={{display: 'flex'}}>
                                    <div style={{width: 20, height: 20, border: '1px solid black'}}>

                                    </div>
                                    <div style={{width: 20, height: 20, marginLeft: 2, border: '1px solid black'}}>

                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>БП</td>
                            <td>
                                <div style={{display: 'flex'}}>
                                    <div style={{width: 20, height: 20, border: '1px solid black'}}>

                                    </div>
                                    <div style={{width: 20, height: 20, marginLeft: 2, border: '1px solid black'}}>

                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Царапины</td>
                            <td>
                                <div style={{display: 'flex'}}>
                                    <div style={{width: 20, height: 20, border: '1px solid black'}}>

                                    </div>
                                    <div style={{width: 20, height: 20, marginLeft: 2, border: '1px solid black'}}>

                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Вмятины</td>
                            <td>
                                <div style={{display: 'flex'}}>
                                    <div style={{width: 20, height: 20, border: '1px solid black'}}>

                                    </div>
                                    <div style={{width: 20, height: 20, marginLeft: 2, border: '1px solid black'}}>

                                    </div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div>
                <span style={{fontSize: 13}}><b>Заявленная неисправность: </b></span>
                <span style={{fontSize: 13}}>{problem}</span>
            </div>
            <hr style={{width: '100%', marginBottom: 10, marginTop: 5}}/>
            <Rules client={client} manager={manager}/>
        </div>
    )
};
const Rules = ({client, manager, addStamp}) => {
    return (
        <>
            <div>
                <ol type="1" start="1" style={{fontSize: 11}}>
                    <li>Предварительная диагностика или ремонт производится в срок от 20 минут до 5 дней. Если требуемые
                        запчасти необходимо заказать у производителя, то срок ремонта может быть увеличен до 45 дней.
                    </li>

                    <li>Ремонт выполняется только тех неисправностей, которые указаны в квитанции.</li>

                    <li>СЦ не несет никакой ответственности за сохранность личных данных на жестком диске, контактов и
                        информации в памяти устройства, SIM-карт, карт FLASH памяти, чехлов, батареек, аксессуаров.
                    </li>

                    <li>Гарантия распространяется только на проведенные работы и установленные запчасти.</li>

                    <li>Гарантия не распространяется на устройства, неисправности которых были вызваны механическими
                        повреждениями или попаданием влаги.
                    </li>
                    <li>Аппарат принимается без проведения диагностики, как есть. Сдавая аппарат в ремонт, клиент
                        соглашается, что все неисправности, которые будут обнаружены во время ремонта возникли перед
                        сдачей аппарата в ремонт.
                    </li>
                    <li>Бесплатное хранение отремонтированной техники в нашем сервисе осуществляется первые 15 дней. За
                        хранение техники после 16 марта 2021 г. СЦ взимает плату в размере 100 рублей за каждые сутки.
                    </li>
                    <li>Я даю свое согласие на обработку персональных данных, содержащихся в настоящей анкете, в целях
                        направления информации о статусе заказа а также стоимости услуг ИП Тучак С.С.
                    </li>
                </ol>
            </div>
            <table style={{fontSize: 14, width: '100%', marginTop: 50}}>
                <tbody>
                <tr>
                    <td width="30%">
                        С условиями согласен:
                    </td>

                    <td align="right" width="30%">
                        _________________/
                    </td>

                    <td>
                        {client.name}
                    </td>
                    <td width="10%">
                        /
                    </td>
                </tr>
                <tr>
                    <td><br/></td>
                </tr>
                <tr>
                    <td width="30%" style={{position: 'relative'}}>
                        Принял:
                        {addStamp && (
                            <img src={stamp} width={100} style={{position: 'absolute', top: -10, left: 200}}/>
                        )}
                    </td>

                    <td align="right" width="30%">
                        _________________/
                    </td>

                    <td>
                        {manager}
                    </td>
                    <td width="10%">
                        /
                    </td>
                </tr>
                </tbody>
            </table>
        </>
    )
};

