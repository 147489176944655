import React, {useEffect, useContext, useRef, useReducer} from "react";
import PropTypes from 'prop-types'
import {Table} from "antd";
import _ from 'lodash'
import {useHttp} from "../../hooks/http.hook";
import {AuthContext} from "../../context/AuthContext";
import DefaultForm from "../DefaultForm/DefaultForm";
import RangePickerDate from "../RangePicker";
import tableTitle from "./tableTitle";
import {tableReducer} from "./reducer";
import {ACTIONS} from "./actions";

const initialState = {
    isUpdate: false,
    visibleDrawer: false,
    recordId: null,
    formValues: [],
    data: [],
    pagination: {
        page: 1,
        pageSize: 40,
        total: 0
    },
    order: {column: undefined, direction: undefined},
    filter: "",
    searchValue: ""
};

function initState() {
    return initialState
}

const DefaultTable = (props) => {
    const {
        schemaId, tableColumns, title, form, formRenderer, drawerWidth,
        onRowClick, formWrapper, filterByOffice, predefinedFilter,
        predefinedOrder, hideCreate, onCreateCallback, isRangeFilter, tableSearchCols
    } = props;
    const formRef = useRef();
    const {request, loading} = useHttp();
    const {currentOffice} = useContext(AuthContext);
    const [state, dispatch] = useReducer(tableReducer, undefined, initState);
    const {isUpdate, visibleDrawer, recordId, formValues, data, pagination, order, filter, searchValue} = state;

    useEffect(() => {
        async function setInitialData() {
            if (filterByOffice && !currentOffice) return;
            if (predefinedOrder) {
                dispatch({type: ACTIONS.SET_ORDER, payload: predefinedOrder});
            }
            if (predefinedFilter) {
                //dispatch({type: ACTIONS.SET_FILTER, payload: {...filter, ...predefinedFilter}})
            }
            await fetchData(pagination, order, searchValue);
        }

        setInitialData();
    }, [schemaId, currentOffice, JSON.stringify(predefinedFilter)]);

    //fetch table data
    const fetchData = async (pagination, order, searchValue, filters) => {
        let paginate = `?page=${pagination.page}&pageSize=${pagination.pageSize}`;
        let sort = `&sort=${generateOrder(order)}`;
        let search = `&search=${searchValue}`;
        let generatedFilter = generateFilter(filters);
        const params = paginate + sort + search + generatedFilter.filterString;
        const data = await request(`/api/${schemaId}/query${params}`, 'GET');
        dispatch({
            type: ACTIONS.REFRESH_DATA,
            payload: {
                data: data ? data.content ? data.content : [] : [],
                pagination: {total: data.totalElements}
            }
        });

    };

    const generateFilter = (filterCols = {}) => {
        let filterOffice = filterByOffice ? {office: currentOffice ? currentOffice.value : null} : {};
        let cleanedObject = _.pickBy({...filter, ...filterCols}, v => v !== null);
        let newFilter = {...predefinedFilter, ...cleanedObject, ...filterOffice}

        return {
            filterString: `&filter=${JSON.stringify(newFilter)}`,
            filterObject: newFilter,
            stateFilter: {...cleanedObject, ...filter}
        };
    };
    const generateOrder = (order) => {
        if (order.column) {
            return JSON.stringify({column: order.column, direction: order.direction})
        }

        if (predefinedOrder) {
            return JSON.stringify(predefinedOrder)
        }

        //return default sort
        return "{}"
    };

    //CRUD events
    const readRecord = async (id) => {
        const {enrichment = {}} = form;
        try {
            const response = await request(`/api/${schemaId}/read`, 'POST', {id});
            let convertedData = [];
            if (Object.keys(enrichment).length > 0) {
                for (let key of Object.keys(response)) {
                    //is array
                    if (_.isArray(response[key])) {
                        response[key] = response[key].map(i => {
                            return {label: i[enrichment[key].label], value: i[enrichment[key].value]}
                        })
                    }
                    //is object
                    if (_.isPlainObject(response[key])) {
                        response[key] = {
                            label: response[key][enrichment[key].label],
                            value: response[key][enrichment[key].value]
                        }
                    }
                }
            }
            for (let key of Object.keys(response)) {
                convertedData.push({
                    name: [key],
                    value: response[key] || ""
                })
            }
            dispatch({type: ACTIONS.SET_RECORD_ID, payload: id});
            dispatch({type: ACTIONS.SET_FORM_VALUES, payload: convertedData});
        } catch (e) {

        }

        setVisible(false, true)
    };
    const deleteRecord = async () => {
        try {
            await request(`/api/${schemaId}/delete`, 'POST', {id: recordId});
            setVisible(true, true);
            await fetchData(pagination, order, searchValue)
        } catch (e) {

        }
    };
    const saveRecord = async (record) => {
        const {enrichment = {}} = form;
        if (Object.keys(enrichment).length > 0) {
            for (let key of Object.keys(record)) {
                if (_.isArray(record[key])) {
                    record[key] = record[key].map(i => i.value)
                }
                if (_.isPlainObject(record[key])) {
                    record[key] = record[key].value
                }
            }
        }
        try {
            isUpdate ?
                await request(`/api/${schemaId}/update`, 'POST', {id: recordId, ...record})
                :
                await request(`/api/${schemaId}/create`, 'POST', record);

            setVisible(true, false);
            formRef.current && formRef.current.resetFields();
            await fetchData(pagination, order, searchValue);
            await onCreateCallback()
        } catch (e) {
            console.log(e)
        }
    };

    //table events (paging, search, order)
    const handleTableChange = async ({current, pageSize}, filters, sorter) => {
        const {column: {dataIndex} = {}, order} = sorter;
        dispatch({type: ACTIONS.SET_PAGINATION, payload: {page: current, pageSize}});
        dispatch({type: ACTIONS.SET_ORDER, payload: {column: dataIndex, direction: order}});
        dispatch({type: ACTIONS.SET_FILTER, payload: {...filter, ...filters}});
        await fetchData({page: current, pageSize}, {column: dataIndex, direction: order}, searchValue, filters)
    };

    //search handler
    const handleSearchChange = async (string) => {
        let newFilter;
        if (tableSearchCols.length) {
            newFilter = {$or: []};
            tableSearchCols.forEach(col => {
                let a = {};
                a[col] = string;
                newFilter.$or.push(a)
            });
            dispatch({type: ACTIONS.SET_FILTER, payload: newFilter});
        }
        dispatch({type: ACTIONS.SET_SEARCH_VALUE, payload: string});
        await fetchData(pagination, order, string, newFilter)
    };

    const setVisible = (isClose, isUpdate) => {
        dispatch({type: ACTIONS.SET_UPDATE, payload: isUpdate});

        if (!isClose) {
            if (filterByOffice) {
                dispatch({
                    type: ACTIONS.APPEND_OFFICE_FORM, payload: {
                        name: 'office',
                        value: currentOffice ? currentOffice.value : null
                    }
                });
            }
        }
        isUpdate && isClose && formRef.current && formRef.current.resetFields();
        dispatch({type: ACTIONS.TOGGLE_DRAWER})
    };
    const onClickRow = async ({_id}) => {
        await readRecord(_id)
    };
    const refresh = async () => {
        await fetchData(pagination, order, searchValue)
    };
    return (
        <>
            <div style={{margin: '10px 0'}}>
                {isRangeFilter && (
                    <RangePickerDate
                        setRange={(range) => {
                            fetchData(pagination, order, searchValue, {createdAt: range})
                        }}
                        defaultValue={"null"}
                    />
                )}
            </div>
            <Table
                onRow={(record) => {
                    return {
                        onClick: () => onRowClick ? onRowClick(record) : onClickRow(record), // click row
                    };
                }}
                rowKey={record => record._id}
                bordered
                title={() => tableTitle({
                    title,
                    onClick: setVisible,
                    setSearch: handleSearchChange,
                    loading,
                    hideCreate,
                    refresh
                })}
                size={"small"}
                tableLayout={"fixed"}
                loading={loading}
                scroll={{x: true}}
                dataSource={data}
                columns={typeof tableColumns === 'function' ? tableColumns(refresh) : tableColumns}
                pagination={pagination}
                onChange={handleTableChange}
            />
            <DefaultForm
                drawerWidth={drawerWidth}
                formRef={formRef}
                visible={visibleDrawer}
                onClose={setVisible}
                onFinish={saveRecord}
                form={form}
                formId={schemaId}
                formValues={formValues}
                isUpdate={isUpdate}
                onDelete={deleteRecord}
                formRenderer={formRenderer}
                formWrapper={formWrapper}
                fetchData={fetchData}
                onCreateCallback={onCreateCallback}
            />
        </>
    )
};
export default DefaultTable;

DefaultTable.propTypes = {
    title: PropTypes.string,
    schemaId: PropTypes.string,
    tableColumns: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
    tableSearchCols: PropTypes.array.isRequired,
    form: PropTypes.object.isRequired,
    formRenderer: PropTypes.any,
    onRowClick: PropTypes.func,
    formWrapper: PropTypes.string,
    filterByOffice: PropTypes.bool,
    hideCreate: PropTypes.bool,
    onCreateCallBack: PropTypes.func,
    isRangeFilter: PropTypes.bool
};

DefaultTable.defaultProps = {
    tableColumns: [],
    form: {},
    title: "Таблица",
    schemaId: "",
    formWrapper: 'Modal',
    onCreateCallback: () => {
    },
    isRangeFilter: false,
    tableSearchCols: [],
    predefinedFilter: {}
};