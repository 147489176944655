import React, {useEffect, useState} from 'react';
import {Divider, Form, Input, Select} from "antd";
import {DefaultField, SelectRemoteFieldCreatable} from "./FormByType";
import {useHttp} from "../hooks/http.hook";
import {PlusOutlined} from "@ant-design/icons";

const {Option} = Select;

/*
    * 1. Serial Number - STRING
    * 2. Device Type - RemoteSelectCreatable
    * 3. Device Brand - RemoteSelectCreatable (disabled if Device Type not selected)(dropdown filtered by selected type)
    * 4. Device Model - RemoteSelectCreatable (disabled if Device Type & Brand not selected)(filtered by selected type & brand)
    *
*/


const DeviceForm = ({formRef, formValues, onFinish}) => {
    const [disabledBrand, setDisabledBrand] = useState(true);
    const [disabledModel, setDisabledModel] = useState(true);
    const [currentType, setCurrentType] = useState(undefined);
    const [currentBrand, setCurrentBrand] = useState(undefined);
    useEffect(() => {
        if(formValues){
            for (const value of formValues) {
                if (value.name === 'brand' && value.value !== undefined) {
                    setDisabledBrand(true)
                }
                if (value.name === 'model' && value.value !== undefined) {
                    setDisabledBrand(true)
                }
            }
        }
    }, [formValues]);
    const onValuesChange = (changedValue, allValues) => {
        if(changedValue['type']){
            formRef.current && formRef.current.resetFields(['brand', 'model'])
        }
        if (allValues['type'] !== undefined) {
            setCurrentType(allValues['type'].value);
            setDisabledBrand(false)
        }
        if (allValues['brand'] !== undefined) {
            setCurrentBrand(allValues['brand'].value);
            setDisabledModel(false)
        }
    };
    return (
        <Form
            onValuesChange={onValuesChange}
            ref={formRef}
            fields={formValues}
            name={'device'}
            onFinish={onFinish}
            id={'form'}
            layout={'vertical'}
        >
            <DefaultField
                label={'Серийный Номер'}
                name={'serial'}
                rules={[]}
            />
            <SelectRemoteFieldCreatable
                rules={[]}
                name={'type'}
                label={'Тип Устройства'}
                isArray={false}
                dataKeys={{value: '_id', label: 'name'}}
                api={'/deviceType/query'}
                createAPI={'/deviceType/create'}
            />
            <BrandField
                rules={[]}
                name={'brand'}
                label={'Брэнд'}
                isArray={false}
                dataKeys={{value: '_id', label: 'name'}}
                api={'/deviceBrand/query'}
                createAPI={'/deviceBrand/create'}
                disabled={disabledBrand}
                typeId={currentType}
            />
            <ModelField
                rules={[]}
                name={'model'}
                label={'Модель'}
                isArray={false}
                dataKeys={{value: '_id', label: 'name'}}
                api={'/deviceModel/query'}
                createAPI={'/deviceModel/create'}
                disabled={disabledModel}
                typeId={currentType}
                brandId={currentBrand}
            />
        </Form>
    )
};

export default DeviceForm;


const BrandField = ({name, label, rules, api, dataKeys, isArray, createAPI, disabled, typeId}) => {
    const [data, setData] = useState([]);
    const [newItem, setNewItem] = useState('');
    const {request, loading} = useHttp();
    const onOpen = async (isOpen) => {
        if(isOpen){
            await fetchData()
        }
    };
    const onChangeNewItem = ({target: {value}}) => {
        setNewItem(value)
    };
    const addNewItem = async () => {
        await request(`/api${createAPI}`, 'POST', {name: newItem, type: typeId});
        setNewItem('');
        await fetchData();
    };
    const fetchData = async () => {
        const {value, label} = dataKeys;
        const params = `?page=1&pageSize=9999&filter=${JSON.stringify({type: typeId})}`;
        const data = await request(`/api${api}${params}`, 'GET');
        let collectedData = [];
        if (data.content) {
            for (let item of data.content) {
                collectedData.push({value: item[value], label: item[label]})
            }
        }
        setData(collectedData);
    };

    return (
        <Form.Item
            name={name}
            label={label}
            rules={rules}
        >
            <Select
                labelInValue
                showSearch
                filterOption
                allowClear
                options={data}
                optionFilterProp={"label"}
                disabled={disabled}
                onDropdownVisibleChange={onOpen}
                loading={loading}
                mode={isArray && "multiple"}
                dropdownRender={menu => (
                    <div>
                        {menu}
                        <Divider style={{margin: '4px 0'}}/>
                        <div style={{display: 'flex', flexWrap: 'nowrap', padding: 8}}>
                            <Input
                                style={{flex: 'auto'}}
                                value={newItem}
                                onChange={onChangeNewItem}
                            />
                            <a
                                style={{flex: 'none', padding: '8px', display: 'block', cursor: 'pointer'}}
                                onClick={addNewItem}
                            >
                                <PlusOutlined/> Добавить
                            </a>
                        </div>
                    </div>
                )}
            >
                {data.map(option => {
                    return (
                        <Option key={option.value} value={option.value}>
                            {option.label}
                        </Option>
                    )
                })}
            </Select>
        </Form.Item>
    )
};


const ModelField = ({name, label, rules, api, dataKeys, isArray, createAPI, disabled, typeId, brandId}) => {
    const [data, setData] = useState([]);
    const [newItem, setNewItem] = useState('');
    const {request, loading} = useHttp();
    const onOpen = async (isOpen) => {
        if(isOpen){
            await fetchData()
        }

    };
    const onChangeNewItem = ({target: {value}}) => {
        setNewItem(value)
    };
    const addNewItem = async () => {
        await request(`/api${createAPI}`, 'POST', {name: newItem, type: typeId, brand: brandId});
        setNewItem('');
        await fetchData();
    };
    const fetchData = async () => {
        const {value, label} = dataKeys;
        const params = `?page=1&pageSize=9999&filter=${JSON.stringify({type: typeId, brand: brandId})}`;
        const data = await request(`/api${api}${params}`, 'GET');
        let collectedData = [];
        if (data.content) {
            for (let item of data.content) {
                collectedData.push({value: item[value], label: item[label]})
            }
        }
        setData(collectedData);
    };

    return (
        <Form.Item
            name={name}
            label={label}
            rules={rules}
        >
            <Select
                labelInValue
                showSearch
                filterOption
                allowClear
                options={data}
                optionFilterProp={"label"}
                disabled={disabled}
                onDropdownVisibleChange={onOpen}
                loading={loading}
                mode={isArray && "multiple"}
                dropdownRender={menu => (
                    <div>
                        {menu}
                        <Divider style={{margin: '4px 0'}}/>
                        <div style={{display: 'flex', flexWrap: 'nowrap', padding: 8}}>
                            <Input
                                style={{flex: 'auto'}}
                                value={newItem}
                                onChange={onChangeNewItem}
                            />
                            <a
                                style={{flex: 'none', padding: '8px', display: 'block', cursor: 'pointer'}}
                                onClick={addNewItem}
                            >
                                <PlusOutlined/> Добавить
                            </a>
                        </div>
                    </div>
                )}
            >
                {data.map(option => {
                    return (
                        <Option key={option.value} value={option.value}>
                            {option.label}
                        </Option>
                    )
                })}
            </Select>
        </Form.Item>
    )
};