import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {Button, Dropdown, Menu, Popconfirm} from "antd";
import {Badge, Card, Divider, Tag, Typography} from 'antd';
import formatValue from "../../../../utils/formatValue";
import {useHttp} from "../../../../hooks/http.hook";
import EnterKeyModal from "../Setup/EnterKeyModal";

const {Text, Link} = Typography;

const Profile = (props) => {
    const {removeProfile, updateProfile} = props;
    const {request, loading} = useHttp();
    const [profileInfo, setProfileInfo] = useState({
        project: "",
        balance: 0
    });
    useEffect(() => {
        async function getProfile() {
            const response = await request('/api/sms-service/getProfileInfo', 'POST', {});
            setProfileInfo(response)
        }

        getProfile();
    }, []);

    return (
        <Card className={"main-sms-content-container"} loading={loading}>
            <Badge size={"default"} status={"success"} text={'Сервис sms подключен'}/>
            <Dropdown
                overlay={() => <ProfileMenu
                    profileInfo={profileInfo}
                    updateProfile={updateProfile}
                    removeProfile={removeProfile}
                />}
            >
                <Button
                    style={{marginLeft: 5, textTransform: 'capitalize'}}
                >
                    {profileInfo.project}
                </Button>
            </Dropdown>
            <Divider/>
            <Text strong>
                Ваш баланс: <Tag color={"green"}>{formatValue(profileInfo.balance, 'currency')}</Tag>
                <br/><br/>
                Можете пополнить его <Link href="https://mainsms.ru/office/pay_system" target="_blank">по этой
                ссылке</Link>
            </Text>
            <Divider/>
        </Card>
    )
};

export default Profile;

Profile.propTypes = {
    updateProfile: PropTypes.func,
    removeProfile: PropTypes.func
}

const ProfileMenu = ({profileInfo, updateProfile, removeProfile}) => {
    const [isOpenEdit, setIsOpenEdit] = useState(false);
    const [loading, setLoading] = useState(false)
    const [popConfirm, setPopConfirm] = useState(false);

    async function confirmApiKey(credentials) {
        try {
            setLoading(true)
            await updateProfile(credentials);
            setLoading(false)
            setIsOpenEdit(false);
        } catch (e) {
            setLoading(false)
        }
    }

    async function onClickRemove() {
        setPopConfirm(true)
    }

    return (
        <>
            <Menu>
                <Menu.Item onClick={() => setIsOpenEdit(true)}>
                    Изменить профиль
                </Menu.Item>
                <Menu.Item onClick={onClickRemove}>
                    Отключить
                </Menu.Item>
            </Menu>
            <EnterKeyModal
                isOpen={isOpenEdit}
                onClose={() => setIsOpenEdit(false)}
                confirmApiKey={confirmApiKey}
                loading={loading}
                values={profileInfo}
            />
            <Popconfirm
                title={'Вы уверены ?'}
                okText={'Отключить'}
                cancelText={'Отмена'}
                visible={popConfirm}
                onConfirm={async () => {
                    setLoading(true)
                    await removeProfile()
                    setLoading(false)
                }}
                onCancel={() => setPopConfirm(false)}
                okButtonProps={{loading}}
            />
        </>
    )
};