import {ACTIONS} from "./actions";

export const tableReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.SET_UPDATE:
            return {
                ...state, isUpdate: action.payload
            };
        case ACTIONS.TOGGLE_DRAWER:
            return {
                ...state, visibleDrawer: !state.visibleDrawer
            };
        case ACTIONS.SET_RECORD_ID:
            return {
                ...state, recordId: action.payload
            };
        case ACTIONS.SET_FORM_VALUES:
            return {
                ...state, formValues: action.payload
            };
        case ACTIONS.APPEND_OFFICE_FORM:
            return {
                ...state, formValues: [...state.formValues, ...action.payload]
            };
        case ACTIONS.SET_TABLE_DATA:
            return {
                ...state, data: action.payload
            };
        case ACTIONS.REFRESH_DATA:
            return {
                ...state,
                data: action.payload.data,
                pagination: {...state.pagination, ...action.payload.pagination}
            };
        case ACTIONS.SET_PAGINATION:
            return {
                ...state, pagination: action.payload
            };
        case ACTIONS.UPDATE_TOTAL_ELEMENTS:
            return {
                ...state, pagination: {...state.pagination, totalElements: action.payload}
            };
        case ACTIONS.SET_ORDER:
            return {
                ...state, order: action.payload
            };
        case ACTIONS.SET_FILTER:
            return {
                ...state, filter: action.payload
            };
        case ACTIONS.SET_SEARCH_VALUE:
            return {
                ...state, searchValue: action.payload
            };
        default:
            return state
    }
}