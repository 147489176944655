import React from "react";
import DefaultTable from "../components/DefaultTable/DefaultTable";

const form = {
    name: 'stock',
    title: 'Склад запчастей',
    schema: [
        {   type: 'hidden',
            name: 'office',
            label: 'Офис'
        },
        {
            type: 'string',
            name: 'name',
            label: 'Название',
        },
        {
            type: 'selectRemoteCreatable',
            name: 'category',
            label: 'Категория',
            api: '/stockCategory/query',
            createAPI: '/stockCategory/create',
            isArray: false,
            dataKeys: {value: '_id', label: 'name'}
        },
        {
            type: 'volute',
            name: 'price',
            label: 'Цена',
        },
        {
            type: 'number',
            name: 'count',
            label: 'Количество',
        },
    ],
    enrichment: {
        category: {
            label: "name",
            value: "_id"
        }
    }
};

const columns = [
    {
        dataIndex: 'name', title: "Название", sorter: () => {}
    },
    {
        dataIndex: 'price', title: "Цена", sorter: () => {}
    },
    {
        dataIndex: 'count', title: "Кол-во", sorter: () => {}
    },
    {
        dataIndex: ['category', 'name'], title: "Категория", sorter: () => {}
    },
];


const StockPage = () => {
    return (
        <DefaultTable
            schemaId={form.name}
            filterByOffice={true}
            title={form.title}
            form={form}
            tableColumns={columns}
        />
    )
};

export default StockPage;