import React, {useContext} from "react";
import {Button, Layout, Typography} from "antd";
import {MenuOutlined} from '@ant-design/icons';
import {AuthContext} from "../../../context/AuthContext";
import HeaderMenu from "./HeaderMenu";
import './header.css'

const {Header} = Layout;

const HeaderComponent = ({toggleSidebar, sideBarOpen}) => {
    const {currentOffice} = useContext(AuthContext);
    return (
        <Header className="site-layout-background myheader"
                style={{
                    position: "fixed", zIndex: 1,
                    width: sideBarOpen ? 'calc(100% - 200px)' : "100%",
                    color: '#fff',
                    display: "flex",
                    alignItems: "center",
                    padding: "0 15px",
                    boxShadow: '0 1px 4px rgba(0,21,41,.08)',
                    background: '#fff',
                    marginLeft: sideBarOpen ? 200 : 0
                }}
        >
            <div style={{flexGrow: 1, color: "black", display: 'flex', alignItems: 'center'}}>
                <Button icon={<MenuOutlined/>} style={{marginRight: 15}} onClick={toggleSidebar}/>
                <Typography>{currentOffice ? ` ${currentOffice.label}` : ''}</Typography>
            </div>
            <HeaderMenu/>
        </Header>
    )
};

export default HeaderComponent
