import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {Input, Modal, Form} from "antd";

const EnterKeyModal = ({isOpen, onClose, confirmApiKey, loading, values}) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (values) {
            form.setFieldsValue({project: values.project})
        }
    }, []);

    function onSubmit(values) {
        confirmApiKey({
            key: values.key,
            project: values.project
        });
    }

    return (
        <Modal
            open={isOpen}
            okText={'Подключить'}
            cancelText={'Отмена'}
            confirmLoading={loading}
            onOk={form.submit}
            onCancel={onClose}
            centered
        >
            <Form
                form={form}
                name="main-sms-api-project"
                initialValues={{remember: true}}
                onFinish={onSubmit}
                // onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    required={true}
                    type={"string"}
                    name={'project'}
                    rules={[{required: true, message: 'Пожалуйста, введите имя проекта'}]}
                >
                    <Input
                        width={'100%'}
                        autocomplete="off"
                        placeholder={"Имя проекта"}
                        style={{
                            margin: "30px 0 10px 0"
                        }}
                    />
                </Form.Item>
                <Form.Item
                    rules={[{required: true, message: 'Пожалуйста, введите ключ'}]}
                    required={true}
                    type={"sting"}
                    name={'key'}
                    style={{
                        width: '100%'
                    }}
                >
                    <Input visibilityToggle
                           style={{
                               margin: "30px 0 10px 0"
                           }}
                           autocomplete="off"
                           placeholder={"API Ключ, пример: 123c1cb2d2452628ee8c59a9ee442f09"}
                           width={'100%'}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
};

export default EnterKeyModal

EnterKeyModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func
};
