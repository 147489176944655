import React, {useContext, useEffect} from 'react';
import {Row, Col, message} from "antd";
import {Form, Input, Button, Layout} from 'antd';
import {LockFilled} from '@ant-design/icons'
import {red} from '@ant-design/colors';
import {useHttp} from "../hooks/http.hook";
import {AuthContext} from "../context/AuthContext";
import {useNavigate} from "react-router-dom";

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 0,
        span: 0,
    },
};
const AuthPage = () => {
    const {request, loading, error, clearError} = useHttp();
    const navigate = useNavigate();
    const auth = useContext(AuthContext);
    useEffect(() => {
        error && message.error(error, 2);
        clearError()
    }, [error, clearError]);

    const onFinish = async (values) => {
        try {
            const data = await request('/api/auth/login', 'POST', {...values});
            const offices = data.offices.map(o => {return {value: o._id, label: o.name}});
            auth.login(data.token, data.userId, data.userName, data.role, offices);
            navigate('/')
        } catch (e) {}
    };

    return (
        <Layout style={{height: '100vh', paddingTop: 40}}>
            <Row align={'center'} justify={'center'}>
                <Col>
                    <LockFilled style={{fontSize: 38, margin: 10, color: red[7]}}/>
                    <h2>Sign In</h2>
                </Col>
            </Row>

            <Row align={'center'} justify={'center'}>
                <Col>
                    <Form
                        {...layout}
                        name="basic"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        size={"large"}
                    >
                        <Form.Item
                            label="Username"
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your username!',
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                        >
                            <Input.Password/>
                        </Form.Item>

                        <Form.Item {...tailLayout}>
                            <Button disabled={loading} type="primary" htmlType="submit" style={{width: '100%'}}>
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </Layout>
    )
};

export default AuthPage