import {useState, useCallback, useEffect} from 'react'

const storageName = 'userData';

export const useAuth = () => {
    const [token, setToken] = useState(null);
    const [ready, setReady] = useState(false);
    const [userId, setUserId] = useState(null);
    const [userName, setUserName] = useState(null);
    const [role, setRole] = useState(null);
    const [offices, setOffices] = useState(null);
    const [currentOffice, setCurrentOffice] = useState(null);
    const login = useCallback((jwtToken, id, userName, role, offices) => {

        setToken(jwtToken);
        setUserId(id);
        setUserName(userName);
        setRole(role);
        setOffices(offices);
        setCurrentOffice(offices ? offices[0] : {value: "", label: ""});
        localStorage.setItem(storageName, JSON.stringify({
            userId: id, token: jwtToken, userName: userName, role: role, offices
        }))
    }, []);
    const logout = useCallback(() => {
        setToken(null);
        setUserId(null);
        setUserName(null);
        setOffices(null);
        setRole(null);
        setCurrentOffice(null);
        localStorage.removeItem(storageName)
    }, []);

    const changeOffice = (office) => {
        const filtered = offices.filter((o) => o.value === office.value);
        setCurrentOffice(filtered[0])
    };

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem(storageName));
        if (data && data.token) {
            login(data.token, data.userId, data.userName, data.role, data.offices)
        }

        setReady(true)
    }, [login]);

    return {login, logout, token, userId, userName, role, ready, offices, currentOffice, changeOffice}
};
