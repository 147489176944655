import React, {useContext, useState} from "react";
import {Avatar, Dropdown} from "antd";
import {UserOutlined} from "@ant-design/icons";
import CreateOrderFromHeader from "./CreateOrderFromHeader";
import {AuthContext} from "../../../context/AuthContext";
import ChangePassword from "../../ChangePassword";
import SearchByOldOrders from "./SearchByOldOrders";
const HeaderMenu = () => {
    const auth = useContext(AuthContext);
    const [isOpenChangePassword, setIsOpenChangePassword] = useState(false);

    function onClick({key}) {
        switch (key) {
            case 'logout':
                return auth.logout();
            case 'changePwd':
                return setIsOpenChangePassword(true);
            default:
                return;
        }
    }
    return (
        <>
            <div style={{margin: "0 30px"}}>
                {/*<SearchByOldOrders/>*/}
                <CreateOrderFromHeader/>
            </div>
            <Dropdown menu={{
                onClick,
                items: [
                    {label: "Сменить пароль", key: "changePwd"},
                    {label: "Выход", key: "logout"}
                ]
            }}
                      // dropdownRender={() => <UserMenu/>}
            >
                <div style={{height: '100%'}}>
                    <Avatar className="ant-dropdown-link" style={{backgroundColor: '#87d068', marginBottom: 3}}
                            icon={<UserOutlined/>} onClick={e => e.preventDefault()}>
                        {auth.userName}
                    </Avatar>

                </div>

            </Dropdown>
            <ChangePassword
                open={isOpenChangePassword}
                onClose={() => setIsOpenChangePassword(false)}
            />
        </>
    )
};
export default HeaderMenu