import React, {useState} from 'react';
import {DatePicker, Divider, Form, Input, InputNumber, Select, Switch} from 'antd'
import {PlusOutlined} from '@ant-design/icons';
import {useHttp} from "../hooks/http.hook";

const {Option} = Select;
const FormByType = ({field}) => {
    const {type, name, label, rules, options, api, isArray, dataKeys, createAPI} = field;
    switch (type) {
        case 'string':
            return <DefaultField
                name={name}
                label={label}
                rules={rules}
            />;
        case 'date':
            return <DateField
                name={name}
                label={label}
                rules={rules}
            />;
        case 'number':
            return <NumberField
                name={name}
                label={label}
                rules={rules}
            />;
        case 'switch':
            return <SwitchField
                name={name}
                label={label}
                rules={rules}
            />;
        case 'textArea':
            return <AreaField
                name={name}
                label={label}
                rules={rules}
            />;
        case 'select':
            return <SelectField
                name={name}
                label={label}
                rules={rules}
                options={options}
                isArray={isArray}
            />;
        case 'volute':
            return <VoluteField
                name={name}
                label={label}
                rules={rules}
            />;
        case 'selectRemote':
            return <SelectRemoteField
                name={name}
                label={label}
                rules={rules}
                options={options}
                api={api}
                dataKeys={dataKeys}
                isArray={isArray}
            />;
        case 'selectRemoteCreatable':
            return <SelectRemoteFieldCreatable
                name={name}
                label={label}
                rules={rules}
                options={options}
                api={api}
                dataKeys={dataKeys}
                isArray={isArray}
                createAPI={createAPI}
            />;
        case 'hidden':
            return <HiddenField name={name}/>
        default:
            return <DefaultField
                name={name}
                label={label}
                rules={rules}
            />
    }
};
export default FormByType;

const DefaultField = ({name, label, rules, disabled}) => {
    return (
        <Form.Item
            name={name}

            label={label}
            rules={rules}
        >
            <Input disabled={disabled}/>
        </Form.Item>
    )
};

const SelectField = ({name, label, rules, options, isArray, disabled}) => {
    return (
        <Form.Item
            name={name}
            label={label}
            rules={rules}
            mode={isArray && "multiple"}
        >
            <Select
                allowClear
                disabled={disabled}
            >
                {options.map(option => {
                    return (
                        <Option key={option.value} value={option.value} disabled={option.disabled}>
                            {option.label}
                        </Option>
                    )
                })}
            </Select>
        </Form.Item>
    )
};

const SelectRemoteField = ({name, label, rules, api, dataKeys, isArray, disabled}) => {
    const [data, setData] = useState([]);
    const {request, loading} = useHttp();
    const onOpen = async (isOpen) => {
        if (isOpen && data.length === 0) {
            const {value, label} = dataKeys;
            const params = `?page=1&pageSize=9999`;
            const data = await request(`/api${api}${params}`, 'GET');
            let collectedData = [];
            if (data.content) {
                for (let item of data.content) {
                    collectedData.push({value: item[value], label: item[label]})
                }
            }
            setData(collectedData);
        }
    };
    return (
        <Form.Item
            name={name}
            label={label}
            rules={rules}
        >
            <Select
                labelInValue
                onDropdownVisibleChange={onOpen}
                loading={loading}
                mode={isArray && "multiple"}
                disabled={disabled}
            >
                {data.map(option => {
                    return (
                        <Option value={option.value} key={option.value}>
                            {option.label}
                        </Option>
                    )
                })}
            </Select>
        </Form.Item>
    )
};

const SelectRemoteFieldCreatable = ({name, label, rules, api, dataKeys, isArray, createAPI, disabled}) => {
    const [data, setData] = useState([]);
    const [newItem, setNewItem] = useState('');
    const {request, loading} = useHttp();
    const onOpen = async (isOpen) => {
        if (isOpen && data.length === 0) {
            await fetchData()
        }
    };
    const onChangeNewItem = ({target: {value}}) => {
        setNewItem(value)
    };
    const addNewItem = async () => {
        await request(`/api${createAPI}`, 'POST', {name: newItem});
        setNewItem('');
        await fetchData();
    };
    const fetchData = async () => {
        const {value, label} = dataKeys;
        const params = `?page=1&pageSize=9999`;
        const data = await request(`/api${api}${params}`, 'GET');
        let collectedData = [];
        if (data.content) {
            for (let item of data.content) {
                collectedData.push({value: item[value], label: item[label]})
            }
        }
        setData(collectedData);
    };

    return (
        <Form.Item
            name={name}
            label={label}
            rules={rules}
        >
            <Select
                labelInValue
                showSearch
                disabled={disabled}
                onDropdownVisibleChange={onOpen}
                loading={loading}
                mode={isArray && "multiple"}
                options={data}
                filterOption
                optionFilterProp={"label"}
                allowClear
                dropdownRender={menu => (
                    <div>
                        {menu}
                        <Divider style={{margin: '4px 0'}}/>
                        <div style={{display: 'flex', flexWrap: 'nowrap', padding: 8}}>
                            <Input
                                style={{flex: 'auto'}}
                                value={newItem}
                                onChange={onChangeNewItem}
                            />
                            <a
                                style={{flex: 'none', padding: '8px', display: 'block', cursor: 'pointer'}}
                                onClick={addNewItem}
                            >
                                <PlusOutlined/> Добавить
                            </a>
                        </div>
                    </div>
                )}
            >
                {data.map(option => {
                    return (
                        <Option value={option.value} key={option.value}>
                            {option.label}
                        </Option>
                    )
                })}
            </Select>
        </Form.Item>
    )
};

const DateField = ({name, label, rules, disabled}) => {
    return (
        <Form.Item
            label={label}
            name={name}
            rules={rules}
        >
            <DatePicker disabled={disabled}/>
        </Form.Item>
    )
};

const NumberField = ({name, label, rules, disabled, prefix}) => {
    return (
        <Form.Item
            name={name}
            label={label}
            rules={rules}
        >
            <InputNumber
                style={{width: '100%'}}
                disabled={disabled}
                prefix={prefix}
            />
        </Form.Item>
    )
};

const VoluteField = ({name, label, rules, disabled, prefix}) => {
    return (
        <Form.Item
            name={name}
            label={label}
            rules={rules}
        >
            <Input
                type={"number"}
                disabled={disabled}
                prefix={prefix ? prefix : '₽'}
            />
        </Form.Item>
    )
};

const SwitchField = ({name, label, rules, disabled}) => {
    return (
        <Form.Item
            label={label}
            name={name}
            rules={rules}
            valuePropName="checked"
        >
            <Switch disabled={disabled}/>
        </Form.Item>
    )
};

const AreaField = ({name, label, rules, disabled}) => {
    return (
        <Form.Item name={name} label={label} rules={rules}>
            <Input.TextArea disabled={disabled}/>
        </Form.Item>
    )
};

const HiddenField = ({name}) => {
    return(
        <Form.Item
            name={name}
            style={{display: 'none'}}
        >
            <Input/>
        </Form.Item>
    )
};

export {
    DefaultField,
    SelectField,
    SelectRemoteField,
    SelectRemoteFieldCreatable,
    DateField,
    NumberField,
    SwitchField,
    AreaField,
    VoluteField,
    HiddenField
}