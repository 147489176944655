import React, {useContext, useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {Layout, Menu, Select} from 'antd';
import {AuthContext} from "../../../context/AuthContext";
import {adminMenu, managerMenu} from "./menuSource";
import {useNavigate} from "react-router-dom";

const {Sider} = Layout;

const SideBar = ({sideBarOpen, toggleSideBar}) => {
    const [menu, setMenu] = useState([]);
    const [officesOptions, setOfficesOptions] = useState([]);
    const [officeSelected, setOfficeSelected] = useState({value: "", label: ""});
    const {role, offices, currentOffice, changeOffice} = useContext(AuthContext);
    const navigate = useNavigate();
    useEffect(() => {
        if (currentOffice && offices) {
            setOfficesOptions(offices);
            setOfficeSelected(currentOffice)
        } else {
            setOfficesOptions([]);
            setOfficeSelected({value: "", label: ""})
        }
    }, [offices, currentOffice]);
    useEffect(() => {
        if(role) {
            setMenu(role === 'admin' ? adminMenu : managerMenu)
        }
    }, [role]);
    function onClick({item}) {
        if(item.props.url) {
            navigate(item.props.url)
        }
    }
    return (
        <Sider
            collapsible
            collapsed={!sideBarOpen}
            onCollapse={toggleSideBar}
            breakpoint="lg"
            collapsedWidth="0"
            trigger={null}
            style={{overflow: 'auto'}}
        >
            <div style={{
                background: 'rgba(255, 255, 255, 0.2)',
                margin: '16px',
            }}>
                <div style={{width: '100%'}}>
                    <Select
                        style={{width: '100%'}}
                        value={officeSelected}
                        onChange={changeOffice}
                        labelInValue
                        options={officesOptions}
                    />
                </div>
            </div>
            <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']} items={menu} onClick={onClick}/>
        </Sider>
    )
};

export default SideBar;

SideBar.propTypes = {
    sideBarOpen: PropTypes.bool,
    toggleSideBar: PropTypes.func
}