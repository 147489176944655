import React from "react";
import DefaultTable from "../components/DefaultTable/DefaultTable";

const form = {
    name: 'outsource',
    title: 'Аутсорс сервисы',
    schema: [
        {
            type: 'string',
            name: 'name',
            label: 'Наименование',
        }
    ]
};

const columns = [
    {
        dataIndex: 'name', title: "Наименование", sorter: () => {}
    }
];


const Outsource = () => {
    return (
        <DefaultTable
            schemaId={form.name}
            title={form.title}
            form={form}
            tableColumns={columns}
            tableSearchCols={['name']}
        />
    )
};

export default Outsource;