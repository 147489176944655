import React from "react";
import {Button, Tooltip} from "antd";
import EditFilled from "@ant-design/icons/lib/icons/EditFilled";
import PrintOrder from "../../PrintOrder";
import ShowOrderHistory from "../../ShowOrderHistory";

const DetailHeader = ({record, color, label, onEditOrder}) => {
    return (
        <div className="order-heading-wrapper">
            <h2 className="order-description-item-wrapper-heading" style={{marginBottom: 24}}>
                Заказ № {record.orderNo}&nbsp;
                <span style={{color}}>
                    ({label})
                </span>
                <Tooltip title={'Править заказ'}>
                    <Button icon={<EditFilled/>} style={{marginLeft: 10}} onClick={onEditOrder}/>
                </Tooltip>

            </h2>

            <div style={{marginRight: 40}}>
                <PrintOrder record={record}/>
                <ShowOrderHistory record={record}/>
            </div>
        </div>
    )
};

export default DetailHeader