import React, {useContext, useEffect, useState} from "react";
import {
    AreaField,
    SelectField,
    SelectRemoteField,
    SelectRemoteFieldCreatable, SwitchField,
    VoluteField
} from "../FormByType";
import {Checkbox, Form, Input, Radio, Select} from "antd";
import {AuthContext} from "../../context/AuthContext";

const {Option} = Select;
const layout = {
    wrapperCol: {span: 0, offset: 0},
};
const formItemLayout = {
    labelCol: {
        span: 0,
    },
    wrapperCol: {
        span: 0,
    }
};

const paymentTypes = [{value: 'cash', label: 'Нал'}, {value: 'card', label: 'Безнал'}];

const ChangeStatusForm = ({record, onFinish}) => {
    const {userId} = useContext(AuthContext);
    const [formValues, setFormValues] = useState([]);
    const [statusOptions, setStatusOptions] = useState([]);
    const [isVisiblePaymentType, setIsVisiblePaymentType] = useState(false);
    const [isVisibleCommission, setIsVisibleCommission] = useState(false);
    const [isVisibleExpenses, setIsVisibleExpenses] = useState(false);
    const [isOtherExpenses, setIsOtherExpenses] = useState(false);
    const [isOutsource, setIsOutsource] = useState(false);
    const [disabledGuarantee, setDisabledGuarantee] = useState(false);
    useEffect(() => {
        const values = [
            {name: 'executor', value: {value: record.executor._id, label: record.executor.name}},
            {name: 'status', value: record.status},
            {name: 'price', value: Number(record.price)},
            {name: 'manager', value: userId},
            {name: 'paymentType', value: record.paymentType},
            {name: 'commission', value: record.commission},
            {
                name: 'outsource', value: {
                    value: record.outsource ? record.outsource._id : null,
                    label: record.outsource ? record.outsource.name : null
                }
            },
            {name: 'otherExpenses', value: record.expenses},
            {name: 'guarantee', value: record.guarantee ? record.guarantee.number : null},
            {name: 'guaranteeType', value: record.guarantee ? record.guarantee.type : null},
            {name: 'withoutGuarantee', value: record.guarantee ? record.guarantee.withoutGuarantee : null}
        ];
        const statusOptions = setOptions();
        setFormValues(values);
        setStatusOptions(statusOptions);
        setIsVisibleExpenses(record.status === 'done');
        setIsOutsource(record.status === 'outsource');
        setIsVisiblePaymentType(record.status === 'payed');
        setIsVisibleCommission(record.status === 'payed' && record.paymentType === 'card');
        setDisabledGuarantee(record.guarantee ? record.guarantee.withoutGuarantee : false);
        //setIsOtherExpenses(record.isOtherExpenses)
        setIsOtherExpenses(true)
    }, [record]);
    const setOptions = () => {
        return [
            {value: 'NEW', label: "Новый"},
            {value: 'diagnosed', label: 'Продиагностирован'},
            {value: 'approval', label: "На согласовании"},
            {value: 'needAttention', label: "Требует внимание"},
            {value: 'waitForPieces', label: "Ожидание З/Ч"},
            {value: 'outsource', label: "Аутсорс"},
            {value: 'inProgress', label: "В работе"},
            {value: 'refused', label: 'Отказ'},
            {value: 'done', label: "Готов"},
            {value: 'payed', label: "Оплачен"}
        ];
    };
    const onChangeForm = (currentChange, allValues) => {

        setDisabledGuarantee(Boolean(allValues.withoutGuarantee));
        setIsOutsource(Boolean(allValues.status === 'outsource'));

        if (allValues.status === 'payed') {
            setIsVisiblePaymentType(true);
            setIsVisibleExpenses(false);
            //show card type
            setIsVisibleCommission(Boolean(allValues.paymentType === 'card'));
            return
        }
        if (allValues.status === 'done') {
            setIsVisibleExpenses(true);
            setIsVisiblePaymentType(false);
            setIsVisibleCommission(false);
            return
        }

        setIsVisibleExpenses(false);
        setIsVisiblePaymentType(false);
        setIsVisibleCommission(false)
    };
    return (
        <Form
            {...layout}
            {...formItemLayout}
            id={'statusForm'}
            layout={'vertical'}
            fields={formValues}
            onValuesChange={onChangeForm}
            onClick={(event => event.stopPropagation())}
            onFinish={onFinish}
        >
            <div>
                <div>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <Form.Item
                            name={'manager'}
                            value={userId}
                            style={{display: 'none'}}
                        >
                            <Input/>
                        </Form.Item>
                        <div style={{width: '50%'}}>
                            <SelectField name={'status'} label={'Статус'} options={statusOptions}
                                         rules={[{required: true}]}/>
                        </div>
                        <div style={{width: '40%'}}>
                            <SelectRemoteField
                                rules={[{required: true}]}
                                name={'executor'}
                                label={'Мастер'}
                                dataKeys={{value: "_id", label: "name"}}
                                api={'/executor/query'}
                            />
                        </div>
                    </div>

                    {/* status = outsource */}
                    {isOutsource && (
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div style={{width: '100%'}}>
                                <SelectRemoteFieldCreatable
                                    rules={[{required: true}]}
                                    name={'outsource'}
                                    label={'Аутсорс Сервис'}
                                    dataKeys={{value: "_id", label: "name"}}
                                    api={'/outsource/query'}
                                    createAPI={'/outsource/create'}
                                />
                            </div>
                        </div>
                    )}

                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div style={{width: '50%'}}>
                            <VoluteField
                                label={'Цена ремонта'}
                                name={'price'}
                                prefix="₽"
                            />
                        </div>
                        {/* status = payed */}
                        {isVisiblePaymentType && (
                            <div style={{width: '40%'}}>
                                <SelectField
                                    name={'paymentType'}
                                    label={'Тип Оплаты'}
                                    options={paymentTypes}
                                    rules={[{required: true}]}
                                />
                            </div>
                        )}
                    </div>

                    {/* status = payed && paymentType = card */}
                    {isVisiblePaymentType && isVisibleCommission && (
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Form.Item name="commission" label="Комиссия">
                                <Radio.Group size={"small"}>
                                    <Radio value={2}>2%</Radio>
                                    <Radio value={1}>1%</Radio>
                                    <Radio value={0}>0%</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </div>
                    )}

                    <AreaField label={'Комментарий'} name={'comment'}/>
                </div>
                {/* expenses */}
                <div>
                    {isVisibleExpenses && (
                        <div>
                            <div>
                                <div>
                                    <p><b>Расход</b></p>
                                    <hr style={{borderTop: '1px solid #1890ff'}}/>
                                </div>
                                {/*<div>*/}
                                {/*    <SelectRemoteField*/}
                                {/*        name={'usedDetails'}*/}
                                {/*        rules={[{required: !isOtherExpenses}]}*/}
                                {/*        label={'Использованные Запчасти'}*/}
                                {/*        api={'/stock/query'}*/}
                                {/*        dataKeys={{value: '_id', label: 'name'}}*/}
                                {/*        isArray={true}*/}
                                {/*    />*/}
                                {/*</div>*/}
                                <div style={{display: 'flex'}}>
                                    {/*<div style={{width: '40%'}}>*/}
                                    {/*<SwitchField*/}
                                    {/*    label={'Сторонние расходы'}*/}
                                    {/*    name={'isOtherExpenses'}*/}
                                    {/*/>*/}
                                    {/*</div>*/}
                                    {isOtherExpenses && (
                                        <div style={{width: '100%', display: 'flex'}}>
                                            <div style={{width: '25%'}}>
                                                <VoluteField
                                                    name={'otherExpenses'}
                                                    label={'Сумма'}
                                                    prefix="₽"
                                                    rules={[{required: true}]}
                                                />
                                            </div>
                                            <div style={{width: '75%', paddingLeft: 10}}>
                                                <AreaField
                                                    label={'Описание'}
                                                    name={'otherExpensesComment'}
                                                    rules={[{required: true}]}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div>
                                <div>
                                    <p><b>Гарантия</b></p>
                                    <hr style={{borderTop: '1px solid #1890ff'}}/>
                                </div>
                                <div>
                                    <Form.Item name={'withoutGuarantee'} valuePropName="checked">
                                        <Checkbox>
                                            Без Гарантии
                                        </Checkbox>
                                    </Form.Item>
                                </div>
                                <div style={{display: 'flex'}}>
                                    <Form.Item name={'guarantee'}>
                                        <Input
                                            type={'number'}
                                            addonAfter={SelectAfter(disabledGuarantee)}
                                            disabled={disabledGuarantee}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Form>
    )
};

export default ChangeStatusForm


const SelectAfter = (disabled) => {
    return (
        <Form.Item name="guaranteeType" noStyle initialValue={'month'}>
            <Select defaultValue="month" className="select-after" disabled={disabled}>
                <Option value="month">Месяц</Option>
                <Option value="day">День</Option>
                <Option value="week">Неделя</Option>
            </Select>
        </Form.Item>
    )
};