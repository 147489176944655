import React from "react";
import {
    AliwangwangOutlined,
    AppstoreOutlined,
    BarChartOutlined, BuildFilled, CloudOutlined,
    DollarCircleFilled,
    ExceptionOutlined, LaptopOutlined,
    LineChartOutlined, MoneyCollectFilled,
    SettingFilled, ShopOutlined, ShoppingCartOutlined,
    TeamOutlined, UserSwitchOutlined, MessageFilled, CodeFilled, UploadOutlined
} from "@ant-design/icons";

const adminMenu = [
    {
        label: "Главная",
        url: "/",
        key: "/",
        icon: <BarChartOutlined/>
    },
    {
        label: "Финансы",
        url: "/finances",
        key: "/finances",
        icon: <DollarCircleFilled/>
    },
    {
        label: "Аналитика",
        url: "/analytics",
        key: "/analytics",
        icon: <LineChartOutlined/>
    },
    {
        label: "Админ",
        icon: <SettingFilled/>,
        key: "adminSettings",
        children: [
            {
                label: "Пользователи",
                url: "/users",
                key: "/users",
                icon: <TeamOutlined/>
            },
            {
                label: "Исполнители",
                url: "/executors",
                key: "/executors",
                icon: <ExceptionOutlined/>
            },
            {
                label: "Офисы",
                url: "/offices",
                key: "/offices",
                icon: <BuildFilled/>
            }
        ]
    },
    {
        label: "Система",
        key: "systemSettings",
        icon: <CodeFilled/>,
        children: [
            {
                label: "Main SMS",
                url: "/system/main-sms",
                key: "/system/main-sms",
                icon: <MessageFilled/>
            }
        ]
    },
    {
        label: "Менеджмент",
        key: "management",
        icon: <AppstoreOutlined/>,
        children: [
            {
                label: "Заказы",
                url: "/orders",
                key: "/orders",
                icon: <ShoppingCartOutlined/>
            },
            {
                label: "Оплаченные",
                url: "/payed-orders",
                key: "/payed-orders",
                icon: <MoneyCollectFilled/>
            },
            {
                label: "Клиенты",
                url: "/clients",
                key: "/clients",
                icon: <UserSwitchOutlined/>
            },
            {
                label: "Устройства",
                url: "/devices",
                key: "/devices",
                icon: <LaptopOutlined/>
            },

        ]
    },
    {
        label: "Прочее",
        key: "otherSettings",
        icon: <CloudOutlined/>,
        children: [
            {
                label: "Откуда Узнали",
                url: "/whereKnown",
                key: "/whereKnown",
                icon: <AliwangwangOutlined/>
            },
            {
                label: "Внешний вид устройства",
                url: "/deviceAppearance",
                key: "/deviceAppearance",
                icon: <AliwangwangOutlined/>
            },
            {
                label: "Загрузка старых заказов",
                url: "/uploadOldData",
                key: "/uploadOldData",
                icon: <UploadOutlined/>
            },
        ]
    },
];
const managerMenu = [
    {
        label: "Главная",
        url: "/",
        key: "/",
        icon: <BarChartOutlined/>
    },
    {
        label: "Менеджмент",
        icon: <AppstoreOutlined/>,
        key: "management",
        children: [
            {
                label: "Заказы",
                url: "/orders",
                key: "/orders",
                icon: <ShoppingCartOutlined/>
            },
            {
                label: "Оплаченные",
                url: "/payed-orders",
                key: "/payed-orders",
                icon: <MoneyCollectFilled/>
            },
            {
                label: "Клиенты",
                url: "/clients",
                key: "/clients",
                icon: <UserSwitchOutlined/>
            },
            {
                label: "Устройства",
                url: "/devices",
                key: "/devices",
                icon: <LaptopOutlined/>
            },

        ]
    },
    {
        label: "Прочее",
        icon: <CloudOutlined/>,
        key: "otherSettings",
        children: [
            {
                label: "Откуда Узнали",
                url: "/whereKnown",
                key: "/whereKnown",
                icon: <AliwangwangOutlined/>
            },
            {
                label: "Внешний вид устройства",
                url: "/deviceAppearance",
                key: "/deviceAppearance",
                icon: <AliwangwangOutlined/>
            }
        ]
    },
];


export {adminMenu, managerMenu}


// {
//     label: "Склад",
//         icon: <ShopOutlined/>,
//     children: [
//     {
//         label: "Склад Запчастей",
//         url: "/stock",
//         icon: <AppstoreOutlined/>
//     },
//     {
//         label: "Склад Запчастей",
//         url: "/stock/defect",
//         icon: <AppstoreOutlined/>
//     },
//     {
//         label: "Категории",
//         url: "/stock/categories",
//         icon: <AppstoreOutlined/>
//     }
//
// ]
// },