import React, {useEffect} from 'react';
import {BrowserRouter as Router} from 'react-router-dom'
import {Layout} from 'antd';
import Spin from "antd/lib/spin";
import {io} from 'socket.io-client';
import {useRoutes} from './routes'
import {useAuth} from './hooks/auth.hook'
import {AuthContext} from './context/AuthContext'
import './index.css'

import AppLayout from "./components/AppLayout";

const {Content} = Layout;

function App() {
    const {ready, token, login, logout, userId, userName, role, offices, currentOffice, changeOffice} = useAuth();
    const isAuthenticated = !!token;
    const routes = useRoutes(isAuthenticated);
    // useEffect(() => {
    //     const socket = io("http://localhost:5000", {
    //         auth: token
    //     });
    //     return () => {
    //         socket.disconnect();
    //     }
    // }, [token]);

    if (!ready) {
        return (
            <div style={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Spin/>
            </div>
        )
    }

    return (
        <AuthContext.Provider value={{
            token,
            login,
            logout,
            userId,
            isAuthenticated,
            userName,
            role,
            offices,
            currentOffice,
            changeOffice
        }}>
            <Router>
                <Layout style={{height: '100vh'}}>
                    <AppLayout/>
                    <Content
                        className="site-layout-background"
                        style={{
                            margin: '70px 5px 0 5px',
                            minHeight: 280,
                            overflow: 'auto'
                        }}
                    >
                        {routes}
                    </Content>

                </Layout>
            </Router>
        </AuthContext.Provider>
    );
}

export default App;