import React, {useContext, useEffect, useState} from "react";
import {Card, Divider, Typography, Dropdown, Menu} from "antd";
import {Column} from "@ant-design/charts";
import {DownOutlined} from '@ant-design/icons';
import {useHttp} from "../../hooks/http.hook";
import {AuthContext} from "../../context/AuthContext";
import formatValue from "../../utils/formatValue";

const {Text} = Typography;

const IncomeByMonthsChart = () => {
    const [data, setData] = useState([]);
    const [availableYears, setAvailableYears] = useState([new Date().getFullYear()]);
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const {request, loading} = useHttp();
    const {currentOffice} = useContext(AuthContext);
    const colConfig = {
        data,
        layout: "horizontal",
        xField: 'month',
        yField: 'value',
        seriesField: 'month',
        legend: {position: 'top-left'},
        label: {
            position: 'middle',
            style: {
                fill: '#FFFFFF',
                opacity: 0.8,
            },
            formatter: (v) => {
                return formatValue(v.value, 'currency')
            }
        },
        tooltip: {
            formatter: (data) => {
                return {name: data.month, value: formatValue(data.value, 'currency')}
            },
            showTitle: false
        },
        loading
    };

    useEffect(() => {
        fetchData();
    }, [currentYear, JSON.stringify(currentOffice)]);

    function onChangeYear(year) {
        setCurrentYear(year)
    }

    async function fetchData() {
        if (currentOffice && currentOffice.value) {
            const filter = JSON.stringify({
                office: currentOffice.value,
                year: currentYear
            });
            const result = await request(`/api/analytics/getIncomeByMonths?filter=${filter}`, 'GET');
            setData(result.data)
            setAvailableYears(result.availableYears)
        }
    }

    return (
        <Card hoverable style={{margin: "10px 0"}}>
            <div style={{display: 'flex'}}>
                <Text type={'secondary'} style={{textAlign: 'center'}}>
                    Прибыль по месяцам
                </Text>
                <div style={{marginLeft: 5}}>
                    <SelectYear
                        availableYears={availableYears}
                        currentYear={currentYear}
                        changeYear={onChangeYear}
                    />
                </div>
            </div>
            <Divider style={{margin: '10px 0'}}/>
            <Column {...colConfig} />
        </Card>
    )
};

export default IncomeByMonthsChart

const SelectYear = ({availableYears, currentYear, changeYear}) => {
    const menu = availableYears.map(year => {
        return {label: year, key: year}
    })
    return (
        <div>
            <Dropdown
                menu={{
                    items: menu,
                    onClick: ({key}) => changeYear(key)
                }}

            >
                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    {currentYear} <DownOutlined/>
                </a>
            </Dropdown>
        </div>
    )
};