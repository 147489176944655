import HeaderComponent from "./Header/Header";
import SideBar from "./SideBar";
import React, {useContext} from "react";
import {useSidebar} from "../../hooks/sidebar.hook";
import {AuthContext} from "../../context/AuthContext";

const AppLayout = () => {
    const {toggle, open} = useSidebar();
    const {isAuthenticated} = useContext(AuthContext);
    return (
        <>
            {isAuthenticated && <HeaderComponent toggleSidebar={toggle} sideBarOpen={open}/>}
            {isAuthenticated && <SideBar toggleSideBar={toggle} sideBarOpen={open}/>}
        </>
    )
}

export default AppLayout