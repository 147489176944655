import React from 'react'
import {Routes, Route, Navigate} from 'react-router-dom'
import AuthPage from './pages/AuthPage'
import UserPage from "./pages/UserPage";
import OfficesPage from "./pages/OfficePage";
import UserProfile from "./pages/UserProfile";
import StockPage from "./pages/StockPage";
import StockCategories from "./pages/StockCategories";
import StockDefectPage from "./pages/StockDefect";
import ExecutorPage from "./pages/ExecutorPage";
import ClientPage from "./pages/ClientPage";
import WhereKnown from "./pages/WhereKnown";
import DevicePage from "./pages/DevicePage";
import OrderPage from "./pages/OrderPage";
import DeviceAppearance from "./pages/DeviceAppearance";
import PayedOrders from "./pages/PayedOrders";
import Dashboard from "./pages/Dashboard";
import Finances from "./pages/Finances";
import Outsource from "./pages/Outsource";
import PrintReception from "./components/Print/PrintReception";
import AnalyticsPage from "./pages/AnalyticsPage/AnalyticsPage";
import MainSms from "./pages/system/MainSms/MainSms";
import CreateMailing from "./pages/system/MainSms/CreateMailing";
import UploadOldData from "./pages/UploadOldData/UploadOldData";

const ordersFilter = {status: ['inProgress', 'done', 'NEW', 'diagnosed', 'outsource']};

export const useRoutes = isAuthenticated => {
    if (isAuthenticated) {
        return (
            <Routes>
                <Route path="/" exact element={<Dashboard/>}/>
                <Route path="/printTest" exact element={<PrintReception/>}/>
                <Route path="/profile" exact element={<UserProfile/>} />
                <Route path="/users" exact element={<UserPage/>}/>
                <Route path="/executors" exact element={<ExecutorPage/>}/>
                <Route path="/offices" exact element={<OfficesPage/>} />
                <Route path="/stock" exact element={<StockPage/>} />
                <Route path="/stock/defect" exact element={<StockDefectPage/>}/>
                <Route path="/stock/categories" exact element={<StockCategories/>}/>
                <Route path="/clients" exact element={<ClientPage/>}/>
                <Route path="/whereKnown" exact element={<WhereKnown/>}/>
                <Route path="/outsource" exact element={<Outsource/>}/>
                <Route path="/deviceAppearance" exact element={<DeviceAppearance/>}/>
                <Route path="/devices" exact element={<DevicePage/>}/>
                <Route path="/finances" exact element={<Finances/>}/>
                <Route path="/payed-orders" element={<PayedOrders />}/>
                <Route path="/analytics" element={<AnalyticsPage />}/>
                <Route path="/orders" element={<OrderPage predefinedFilter={ordersFilter}/>}/>
                <Route exact path="/system/main-sms" element={<MainSms/>}/>
                <Route path="/system/main-sms/create-mailing" element={<CreateMailing/>}/>
                <Route path="/uploadOldData" element={<UploadOldData/>}/>
            </Routes>
        )
    }

    return (
        <Routes>
            <Route path="/" exact element={<AuthPage/>}/>
            {/*<Navigate to="/"/>*/}
        </Routes>
    )
};
