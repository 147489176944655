import React, {useState} from 'react';
import {DefaultField, SelectRemoteFieldCreatable} from "../FormByType";
import {Card, AutoComplete, Form, Input} from "antd";
import {useHttp} from "../../hooks/http.hook";
import _ from 'lodash'

const ClientBlock = ({onSelectClient}) => {
    return(
        <Card title={"Клиент"} size="small">
            <SelectRemoteFieldClient
                rules={[{required: true}]}
                name={'client'}
                label={'Номер Телефона'}
                isArray={false}
                dataKeys={{value: '_id', label: 'phone'}}
                api={'/client/query'}
                createAPI={'/client/create'}
                onSelectClient={onSelectClient}
            />
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div style={{width: '40%'}}>
                    <DefaultField name={'clientName'} label={'Имя'}
                                  rules={[{required: true}]}/>
                </div>
                <div style={{width: '40%'}}>
                    <SelectRemoteFieldCreatable
                        name={'whereKnown'}
                        label={'Откуда Узнал'}
                        api={'/whereKnown/query'}
                        createAPI={'/whereKnown/create'}
                        dataKeys={{value: '_id', label: 'name'}}
                        rules={[{required: true}]}
                    />
                </div>
            </div>
        </Card>
    )
};

export default ClientBlock

//client block fields
const SelectRemoteFieldClient = ({name, label, rules, api, dataKeys, disabled, onSelectClient}) => {
    const [data, setData] = useState([]);
    const {request, loading} = useHttp();
    async function onSearch(text) {
        const {label} = dataKeys;
        console.log(text)
        if(text.length > 10) return;
        let filter = {"$or": [
                {phone: { "$regex": text, "$options": "i" }},
                {name: { "$regex": text, "$options": "i" }}
        ]}
        const params = `?page=1&pageSize=150&filter=${JSON.stringify(filter)}`;
        if(text) {
            const data = await request(`/api${api}${params}`, 'GET');
            let collectedData = [];
            if (data.content) {
                for (let item of data.content) {
                    collectedData.push({
                        value: item[label],
                        label: `${item[label]} - ${item.name}`,
                        obj: item
                    })
                }
            }
            setData(collectedData);
            return
        }
        setData([])

    }
    return (
        <Form.Item
            name={name}
            label={label}
            rules={rules}
        >
            <AutoComplete
                showSearch
                onSelect={(v, i) => onSelectClient(i.obj)}
                onSearch={_.debounce(onSearch, 600)}
                backfill
                filterOption={false}
                loading={loading}
                options={data}
            >
                <Input disabled={disabled} placeholder={"7(xxx) xxx xx xx"}/>
            </AutoComplete>
        </Form.Item>
    )
};
