import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types';
import OrderPage from "../../pages/OrderPage";
import {Checkbox, Modal} from "antd";


const ClientOrders = React.memo(({client}) => {
    const [isOpen, setOpen] = useState(false);
    const toggleOpen = (event) => {
        event.stopPropagation();
        setOpen(prevOpen => !prevOpen)
    };
    return (
        <div onClick={event => toggleOpen(event)}>
            <span onClick={(event) => toggleOpen(event)}>
                <b className={"client-orders-wrapper"}>{client.name}</b>,<br/>
                <a
                    href={`tel:+${client.phone}`}
                    onClick={e => e.stopPropagation()}
                >+{client.phone}</a>
            </span>
                <OrdersModal
                    client={client}
                    isOpen={isOpen}
                    toggleOpen={toggleOpen}
                />
        </div>
    )
});
ClientOrders.defaultProps = {
    client: {}
};

ClientOrders.propTypes = {
    client: PropTypes.object.isRequired
};

export default ClientOrders;


const OrdersModal = ({isOpen, client, toggleOpen}) => {
    let defaultFilter = {"client._id": `${client._id}`};
    let statusFilter = ['NEW', 'inProgress', 'outsource', 'done', 'diagnosed', 'approval', 'waitForPieces'];
    const [predefinedFilter, setPredefinedFilter] = useState(defaultFilter);
    useEffect(() => {
        setPredefinedFilter({"client._id": `${client._id}`})
    }, [client]);
    function toggleChecked() {
        setPredefinedFilter(prevState => prevState.status ? defaultFilter : {...prevState, status: statusFilter})
    }

    return (
        <Modal
            open={isOpen}
            title={
                <div onClick={(e) => e.stopPropagation()}>
                    <b>{client.name}</b>,<a
                    href={`tel:+${client.phone}`}
                    onClick={e => e.stopPropagation()}
                >+{client.phone}</a>
                </div>
            }
            onCancel={toggleOpen}
            footer={[]}
            destroyOnClose
            centered
            width={1200}
            bodyStyle={{
                maxHeight: 500,
                overflowY: 'auto'
            }}
        >
            <div onClick={event => event.stopPropagation()}>
                <Checkbox style={{marginLeft: 5}} checked={Boolean(predefinedFilter.status)} onChange={toggleChecked}>
                    Показать заказы в работе
                </Checkbox>
                <OrderPage
                    predefinedFilter={predefinedFilter}
                    hideCreate={true}
                />
            </div>
        </Modal>
    )
};

export {OrdersModal}