import React, {useEffect, useRef, useState} from "react";
import PropTypes from 'prop-types';
import {Button, Dropdown, Menu} from "antd";
import {PrinterFilled} from "@ant-design/icons";
import {useReactToPrint} from 'react-to-print';
import PrintReception from "../Print/PrintReception";
import {useHttp} from "../../hooks/http.hook";
import PrintGuarantee from "../Print/PrintGuarantee";
import {showConfirm} from "./StampConfirmation";

const PrintOrder = React.memo(({record}) => {
    const recepRef = useRef(null);
    const guaranteeRef = useRef(null);
    const [orderRecord, setOrderRecord] = useState({});
    const [guaranteeDisabled, setGuaranteeDisabled] = useState(false)
    const {request, loading} = useHttp();
    const menuItems = [
        {label: "Печать приемки", key: "reception"},
        {label: "Печать гарантии", key: "guarantee"},
    ]
    useEffect(() => {
        if (record.status === 'payed') {
            return setGuaranteeDisabled(false)
        }
        setGuaranteeDisabled(record.status !== 'done');
    }, [record.status])

    const stampConfirm = async (confirmed, type) => {
        if (type === 'guarantee') {
            await openGuaranteePrint(confirmed)
            return
        }
        await openRecepPrint(confirmed)
    }

    const openRecepPrint = async (addStamp) => {
        const response = await request('/api/order/read', 'POST', {id: record._id});
        setOrderRecord({
            addStamp,
            orderId: response.orderNo,
            problem: response.problem,
            createdAt: response.createdAt,
            manager: response.manager.name,
            client: {
                name: response.client.name,
                phone: response.client.phone,
            },
            device: {
                serial: response.device.serial,
                brand: response.device.brand.name,
                model: response.device.model.name,
                password: response.devicePassword
            }
        });
        setTimeout(() => {
            handlePrintReception()
        }, 500)

    };
    const openGuaranteePrint = async (addStamp) => {
        const currentOrder = await request('/api/order/read', 'POST', {id: record._id});
        const orderHistory = await request('/api/orderLog/read', 'POST', {orderId: record._id});
        const orderGuarantee = await request(`/api/order/getGuaranteeText?orderId=${record._id}`, 'GET');
        const findForDone = orderHistory.history.filter(v => v.status === 'done');

        setOrderRecord({
            addStamp,
            orderId: currentOrder.orderNo,
            problem: currentOrder.problem,
            createdAt: currentOrder.createdAt,
            readyDate: currentOrder.readyDate ? currentOrder.readyDate : new Date(),
            manager: currentOrder.manager.name,
            doneComment: findForDone.length ? findForDone[findForDone.length - 1].comment : "",
            price: currentOrder.price,
            guarantee: orderGuarantee.text,
            client: {
                name: currentOrder.client.name,
                phone: currentOrder.client.phone,
            },
            device: {
                serial: currentOrder.device.serial,
                brand: currentOrder.device.brand.name,
                model: currentOrder.device.model.name,
                password: currentOrder.devicePassword
            }
        });
        setTimeout(() => {
            handlePrintGuarantee()
        }, 500)
    };

    const handlePrintReception = useReactToPrint({
        content: () => recepRef.current,
    });

    const handlePrintGuarantee = useReactToPrint({
        content: () => guaranteeRef.current,
    });

    return (
        <>
            <Dropdown dropdownRender={() =>
                <Menu onClick={({key}) => showConfirm(stampConfirm, key)} items={menuItems}/>
            }>
                <Button
                    onClick={(e) => e.stopPropagation()}
                    icon={<PrinterFilled/>}
                />
            </Dropdown>
            <PrintReception
                printRef={recepRef}
                addStamp={orderRecord.addStamp}
                client={orderRecord.client}
                createdAt={orderRecord.createdAt}
                device={orderRecord.device}
                orderId={orderRecord.orderId}
                problem={orderRecord.problem}
                manager={orderRecord.manager}
            />
            <PrintGuarantee
                printRef={guaranteeRef}
                addStamp={orderRecord.addStamp}
                client={orderRecord.client}
                guarantee={orderRecord.guarantee}
                createdAt={orderRecord.createdAt}
                device={orderRecord.device}
                orderId={orderRecord.orderId}
                problem={orderRecord.problem}
                manager={orderRecord.manager}
                readyDate={orderRecord.readyDate}
                doneComment={orderRecord.doneComment}
                price={orderRecord.price}
            />
        </>
    )
})


export default PrintOrder;

PrintOrder.propTypes = {
    record: PropTypes.object
};