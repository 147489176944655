import React, {useState} from "react";
import {Card} from "antd";
import CreateNewMailingMessage from "./CreateNewMailingMessage";

const Mailing = () => {
    const [mailingData, setMailingData] = useState([]);

    return (
        <Card className={mailingData.length ? "main-sms-content-container" : "main-sms-container-not-connected"}>
            {!mailingData.length && (
                <div className={"main-sms-container-not-connected"}>
                    <CreateNewMailingMessage/>
                </div>
            )}
        </Card>
    )
};

export default Mailing;