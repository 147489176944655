import React, {useContext, useEffect, useState} from "react";
import {Col, Row} from "antd";
import {InteractionOutlined, RiseOutlined} from "@ant-design/icons";
import {CardStatistics} from "../../components/Dashboard/StatisticsCard";
import {useHttp} from "../../hooks/http.hook";
import {AuthContext} from "../../context/AuthContext";

const AverageCards = ({dateRange}) => {
    const {request, loading} = useHttp();
    const {currentOffice} = useContext(AuthContext);
    const [data, setData] = useState({});

    useEffect(() => {
        fetchDataAverage()
    }, [JSON.stringify(currentOffice), JSON.stringify(dateRange)]);

    async function fetchDataAverage() {
        if (currentOffice && currentOffice.value) {
            const filter = JSON.stringify({office: currentOffice.value, readyDate: dateRange});
            try {
                const averagePriceAndIncome = await request(`/api/analytics/averagePriceIncome?filter=${filter}`, 'GET');
                setData(averagePriceAndIncome)
            } catch (e) {
                console.log(e)
            }
        }
    }

    return (
        <Row>
            <Col className={'dashboard__card'} xs={24} sm={12} md={12} lg={8} xl={4}>
                <CardStatistics
                    loading={loading}
                    cardItem={{
                        value: data.income,
                        title: 'Средняя прибыль',
                        icon: <RiseOutlined/>,
                        valueType: "currency",
                        valueStyle: {color: '#1890ff'}
                    }}
                />
            </Col>
            <Col className={'dashboard__card'} xs={24} sm={12} md={12} lg={8} xl={4}>
                <CardStatistics
                    loading={loading}
                    cardItem={{
                        value: data.check,
                        title: 'Средний Чек',
                        icon: <InteractionOutlined/>,
                        valueType: "currency",
                        valueStyle: {color: '#13c2c2'}
                    }}
                />
            </Col>
            <Col className={'dashboard__card'} xs={24} sm={12} md={12} lg={8} xl={8}>
                <CardStatistics
                    loading={loading}
                    cardItem={{
                        value: 0,
                        title: 'Затычка',
                        icon: <InteractionOutlined/>,
                        valueType: "currency",
                        valueStyle: {color: '#13c2c2'}
                    }}
                />
            </Col>
            <Col className={'dashboard__card'} xs={24} sm={12} md={12} lg={8} xl={8}>
                <CardStatistics
                    loading={loading}
                    cardItem={{
                        value: 0,
                        title: 'Затычка',
                        icon: <InteractionOutlined/>,
                        valueType: "currency",
                        valueStyle: {color: '#13c2c2'}
                    }}
                />
            </Col>
        </Row>
    )
}

export default AverageCards