import React, {useContext, useEffect, useState} from 'react';
import {Form, Input} from "antd";
import ClientBlock from "./ClientBlock";
import DeviceBlock from "./DeviceBlock";
import OtherBlock from "./OtherBlock";
import {useHttp} from "../../hooks/http.hook";
import {AuthContext} from "../../context/AuthContext";

const validateMessages = {
    required: '${label} обязательное поле!',
    types: {
        email: '${label} не правильный e-mail!',
        number: '${label} не валидное число!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};

const OrderForm = ({formRef, fetchData, visible, onClose, onCreateCallback, formId, recordId}) => {
    const {currentOffice, userId} = useContext(AuthContext);
    const {request} = useHttp();
    const [formValues, setFormValues] = useState([]);
    const [disabledDevice, setDisabledDevice] = useState(false);
    const [disabledBrand, setDisabledBrand] = useState(true);
    const [disabledModel, setDisabledModel] = useState(true);
    const [currentType, setCurrentType] = useState(undefined);
    const [currentBrand, setCurrentBrand] = useState(undefined);

    const onChangeForm = (currentChange, allValues) => {
        if (allValues.client === undefined || allValues.client === '') {
            formRef.current.resetFields(['clientName', 'whereKnown'])
        }
        if (allValues.withoutSerial) {
            setDisabledDevice(true)
            if (allValues.deviceType !== undefined) {
                setDisabledBrand(false);
                setDisabledModel(false);
            }
            return
        }else{
            if(disabledDevice) {
                setDisabledDevice(false)
            }
        }

        if (allValues.device === undefined || allValues.device === '') {
            setDisabledBrand(true);
            setDisabledModel(true);
            formRef.current.resetFields(['deviceModel', 'deviceBrand'])
        } else if (allValues.device !== undefined && allValues.deviceType !== undefined) {
            setDisabledBrand(false);
            setDisabledModel(false);
        }
    };

    useEffect(() => {
        if (visible) {
            setFormValues([
                {name: 'office', value: currentOffice.value},
                {name: 'manager', value: userId},
                {name: 'prepaymentType', value: 'cash'},
                ...formValues
            ]);
            //means that is coming to update record
            if (recordId) {
                readRecord()
            }
        }
        if (!visible && recordId) {
            setFormValues([])
        }
    }, [visible, recordId]);

    async function readRecord() {
        let convertedData = [];
        try {
            let response = await request(`/api/order/read`, 'POST', {id: recordId});
            convertedData.push({
                name: 'orderId',
                value: recordId
            });
            convertedData.push({
                name: 'status',
                value: response.status
            });
            for (let key of Object.keys(response)) {
                if (key === 'client') {
                    let client = response[key] ? response[key] : {phone: "", name: ""}
                    convertedData.push({
                        name: 'client',
                        value: client.phone
                    });
                    convertedData.push({
                        name: 'clientName',
                        value: client.name
                    });
                    convertedData.push({
                            name: 'whereKnown',
                            value: response.whereKnown ? {
                                value: response.whereKnown._id,
                                label: response.whereKnown.name
                            } : {value: "", label: ""}
                        }
                    )
                }
                if (key === 'device') {
                    let device = response[key] ? response[key] : {
                        serial: "",
                        type: {},
                        brand: {},
                        model: {}
                    }
                    convertedData.push({
                        name: 'device',
                        value: device.serial || ''
                    });
                    convertedData.push({
                        name: 'deviceType',
                        value: {value: device.type._id || '', label: device.type.name || ''}
                    });
                    convertedData.push({
                        name: 'deviceBrand',
                        value: {value: device.brand._id || '', label: device.brand.name || ''}
                    });
                    convertedData.push({
                        name: 'deviceModel',
                        value: {value: device.model._id || '', label: device.model.name || ''}
                    });
                    convertedData.push({
                        name: 'devicePin',
                        value: response.devicePassword
                    });
                    convertedData.push({
                        name: 'deviceAppearance',
                        value: (response.appearance && response.appearance.split(',') || []).map(i => ({
                            value: i,
                            label: i
                        }))
                    })
                }
                if (key === 'executor') {
                    let executor = response[key] ? response[key] : {_id: "", name: ""}
                    convertedData.push({
                        name: 'executor',
                        value: {value: executor._id, label: executor.name}
                    })
                }
                if (key === 'price') {
                    convertedData.push({
                        name: key,
                        value: response[key]
                    })
                }
                if (key === 'problem') {
                    convertedData.push({
                        name: key,
                        value: response[key]
                    })
                }
                if (key === 'prepayment') {
                    convertedData.push({
                        name: key,
                        value: response[key]
                    })
                }

            }
            setFormValues([
                {
                    name: 'office',
                    value: currentOffice.value
                },
                {
                    name: 'manager',
                    value: userId
                },
                ...convertedData
            ])
        } catch (e) {
            console.log('READ RECORD ERROR', e)
        }

    }

    const onSelectClient = (value) => {
        const convertedData = [
            {name: 'clientName', value: value.name},
            {
                name: 'whereKnown', value: {value: 'regularClient', label: 'Постоянный клиент'}
            }];
        setFormValues(convertedData);
    };
    const onSelectDevice = (value) => {
        const convertedData = [
            {name: 'deviceType', value: {value: value.type._id, label: value.type.name}},
            {name: 'deviceBrand', value: {value: value.brand._id, label: value.brand.name}},
            {name: 'deviceModel', value: {value: value.model._id, label: value.model.name}},
        ];
        setCurrentType(value.type._id);
        setCurrentBrand(value.brand._id);
        setDisabledBrand(false);
        setDisabledModel(false);
        setFormValues(convertedData);
    };
    const onSelectType = (value) => {
        setCurrentType(value.value)
    };
    const onSelectBrand = (value) => {
        setCurrentBrand(value.value)
    };

    const onFinish = async (record) => {
        //transform appearance from array to string
        const deviceAppearance = transformAppearance(record.deviceAppearance);
        if (recordId) {
            await request(`/api/order/update`, 'POST', {...record, deviceAppearance});
            onClose();
            onCreateCallback && onCreateCallback();
            if (document.getElementById('refreshTableButton')) {
                document.getElementById('refreshTableButton').click();
            }
            return
        }
        await request(`/api/order/create`, 'POST', {...record, deviceAppearance});
        onClose();
        onCreateCallback && onCreateCallback();
        formRef.current && formRef.current.resetFields();
        fetchData && await fetchData();
        if (document.getElementById('refreshTableButton')) {
            document.getElementById('refreshTableButton').click();
        }

    };

    function transformAppearance(appearance = []) {
        let newAppearance = appearance.map(item => {
            return item.value
        });
        return newAppearance.join(',')
    }

    return (
        <Form
            validateMessages={validateMessages}
            onValuesChange={onChangeForm}
            ref={formRef}
            fields={formValues}
            name={'device'}
            onFinish={onFinish}
            id={formId}
            layout={'vertical'}
        >
            <Form.Item
                name={'orderId'}
                style={{display: 'none'}}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                name={'status'}
                style={{display: 'none'}}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                name={'office'}
                value={currentOffice._id}
                style={{display: 'none'}}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                name={'manager'}
                value={userId}
                style={{display: 'none'}}
            >
                <Input/>
            </Form.Item>
            <ClientBlock
                onSelectClient={onSelectClient}
            />
            <DeviceBlock
                onSelectDevice={onSelectDevice}
                onSelectType={onSelectType}
                onSelectBrand={onSelectBrand}
                currentType={currentType}
                disabledBrand={disabledBrand}
                currentBrand={currentBrand}
                disabledModel={disabledModel}
                disabledDevice={disabledDevice}
            />
            <OtherBlock/>
        </Form>
    )
};

export default OrderForm;

OrderForm.defaultProps = {
    onCreateCallback: () => {
    }
};





