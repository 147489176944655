import React from "react";
import DefaultTable from "../components/DefaultTable/DefaultTable";
import {Input} from "antd";

const form = {
    name: 'user',
    title: 'База пользователей',
    schema: [
        {
            type: 'string',
            name: 'username',
            label: 'Логин',
        },
        {
            type: 'string',
            name: 'name',
            label: 'Имя'
        },
        {
            type: 'email',
            name: 'email',
            label: 'Email',
        },
        {
            type: 'select',
            name: 'role',
            label: 'Роль',
            options: [
                {value: 'admin', label: 'Админ'},
                {value: 'manager', label: 'Менеджер'},
            ]
        },
        {
            type: 'selectRemote',
            api: '/office/query',
            name: 'offices',
            label: 'Офисы',
            isArray: true,
            dataKeys: {value: '_id', label: 'name'}
        },
    ],
    enrichment: {
        offices: {
            label: "name",
            value: "_id"
        }
    }
};

const columns = [
    {
        dataIndex: 'name', title: 'Имя'
    },
    {
        dataIndex: 'role', title: "Роль"
    },
];

const UserPage = () => {
    return (
        <DefaultTable
            schemaId={form.name}
            title={form.title}
            form={form}
            tableColumns={columns}
            tableSearchCols={['name', 'role']}
        />
    )
};

export default UserPage;