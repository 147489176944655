import React, {useContext, useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {Button, Divider, Form, Input, Modal, Radio} from "antd";
import {AuthContext} from "../../context/AuthContext";
import {useHttp} from "../../hooks/http.hook";

const {TextArea} = Input;
const locales = {
    getFromCashBox: 'Выдача',
    putInCashBox: 'Пополнение'
}

const CashBoxOperations = ({visible, type, cancel, submit, loading}) => {
    const {currentOffice} = useContext(AuthContext);
    const {request} = useHttp();
    const [availableResources, setAvailableResources] = useState({card: 0, cash: 0})
    const values = [
        {name: 'amount', value: ''},
        {name: 'description', value: ''},
        {name: 'type', value: type},
        {name: 'paymentType', value: 'cash'}
    ]
    useEffect(() => {

        if (visible && type === 'getFromCashBox') {
            fetchData()
        }

    }, [visible, type])

    async function fetchData() {
        try {
            if (currentOffice) {
                const filter = JSON.stringify({office: currentOffice.value});
                const data = await request(`/api/finances/getCashCardMoney?filter=${filter}`, 'GET');
                setAvailableResources({card: data.card, cash: data.cash})
            }
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <Modal
            open={visible}
            title={locales[type]}
            onCancel={cancel}
            maskClosable={false}
            footer={[
                <Button key="close" onClick={cancel}>
                    Отмена
                </Button>,
                <Button key="submit" type="primary" htmlType={"submit"} form={"cashBoxForm"} loading={loading}>
                    Подтвердить
                </Button>,
            ]}
        >
            <Form id={'cashBoxForm'}
                  fields={values}
                  layout={'vertical'}
                  onFinish={submit}
            >
                <Form.Item
                    label="Сумма"
                    name="amount"
                    dependencies={['paymentType']}
                    rules={[
                        {required: Boolean(type === 'getFromCashBox'), message: 'Сумма обязательное поле!'},
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                let paymentType = getFieldValue('paymentType');

                                //only for getFromCashBox type
                                if (Boolean(type !== 'getFromCashBox')) {
                                    return Promise.resolve();
                                }
                                if (value > availableResources[paymentType]) {
                                    return Promise.reject(new Error('Сумма привышает баланс кассы'));
                                }
                                return Promise.resolve();
                            },
                        }),

                    ]}
                >
                    <Input type={'number'}/>
                </Form.Item>
                <Form.Item
                    label="Детали операции"
                    name="description"
                    rules={[{required: true, message: 'Пожалуйста, введите детали операции'}]}
                >
                    <TextArea/>
                </Form.Item>
                <Divider/>
                <Form.Item name="paymentType"
                           label={
                               type === 'getFromCashBox' ? `Выдача с : ` : 'Пополнение :'}>
                    <Radio.Group>
                        <Radio value="cash">Наличные</Radio>
                        <Radio value="card">Безналичные</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label=""
                    name="type"
                    rules={[{required: true, message: 'Пожалуйста, введите детали операции'}]}
                >
                    <Input type={"hidden"}/>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default CashBoxOperations;

CashBoxOperations.propTypes = {
    visible: PropTypes.bool,
    type: PropTypes.string,
    cancel: PropTypes.func,
    submit: PropTypes.func,
    loading: PropTypes.bool
}