import {SearchOutlined} from "@ant-design/icons";
import {Button, Modal, Input} from "antd";
import {useEffect, useRef, useState} from "react";
const { Search } = Input;
const SearchByOldOrders = () => {
    const [isOpen, setIsOpen] = useState(false);
    const searchRef = useRef(null);
    useEffect(() => {
        if(isOpen) {
           // searchRef.current && searchRef.current.focus()
        }
    }, [isOpen])
    async function onSearch(value) {

    }

    return (
        <>
            <Button type="dashed" icon={<SearchOutlined/>} style={{marginRight: 15}}
                    onClick={() => setIsOpen(!isOpen)}
            >
                Поиск по старым заказам
            </Button>
            {isOpen && (<Modal open={isOpen} onCancel={() => setIsOpen(!isOpen)} destroyOnClose>
                <div style={{padding: 10}}>
                    <Input
                        placeholder="input search text"
                        autoFocus={true}
                        allowClear
                       // onSearch={onSearch}
                       // ref={searchRef}
                        style={{
                            width: 200,
                        }}
                    />
                </div>
            </Modal>)}
        </>
    )
}
export default SearchByOldOrders

