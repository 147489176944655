const statuses = [
    {value: 'NEW', label: "Новый", color: '#1890ff'},
    {value: 'diagnosed', label: 'Продиагностирован', color: '#ad8b00'},
    {value: 'approval', label: "На согласовании", color: '#13c2c2'},
    {value: 'needAttention', label: "ТРЕБУЕТ ВНИМАНИЕ", color: '#f5222d'},
    {value: 'waitForPieces', label: "Ожидание З/Ч", color: '#10239e'},
    {value: 'inProgress', label: "В работе", color: '#9254de'},
    {value: 'outsource', label: "Аутсорс", color: '#b37feb'},
    {value: 'done', label: "Готов", color: '#ad6800'},
    {value: 'refused', label: "Отказ", color: '#ad161d'},
    {value: 'payed', label: "Оплачен", color: '#52c41a'},
];


export {statuses}