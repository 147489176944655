import React, {useEffect, useState} from "react";
import {Steps, Table, Button, Typography, message} from 'antd';
import {useHttp} from "../../../../hooks/http.hook";
import './styles.css'
const {Step} = Steps;
const clientColumns = [
    {
        title: "Номер Телефона",
        dataIndex: "phone"
    },
    {
        title: "Имя",
        dataIndex: "name"
    },
    {
        title: "Количество обращений",
        dataIndex: "orders"
    }
];

const CreateMailing = () => {
    return (
        <div className={'group-container'}>
            <CreateGroupSteps/>
        </div>
    )
};

export default CreateMailing

const CreateGroupSteps = () => {
    const [current, setCurrent] = React.useState(0);
    const steps = [
        {
            title: 'Выберите клиентов для группы',
            content: <ClientList/>,
        },
        {
            title: 'Заполните поля',
            content: 'Second-content',
        }
    ];
    const next = () => {
        setCurrent(current + 1);
    };
    const prev = () => {
        setCurrent(current - 1);
    };

    return (
        <>
            <Steps current={current}>
                {steps.map(item => (
                    <Step key={item.title} title={item.title}/>
                ))}
            </Steps>
            <div className="steps-content">{steps[current].content}</div>
            <div className="steps-action">
                {current < steps.length - 1 && (
                    <Button type="primary" onClick={() => next()}>
                        Далее
                    </Button>
                )}
                {current === steps.length - 1 && (
                    <Button type="primary" onClick={() => message.success('Processing complete!')}>
                        Готово
                    </Button>
                )}
                {current > 0 && (
                    <Button style={{margin: '0 8px'}} onClick={() => prev()}>
                        Назад
                    </Button>
                )}
            </div>
        </>
    )
};

const ClientList = ({setSelected}) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [data, setData] = useState({content: [], pageSize: 5, page: 1, totalElements: 0});
    const {request, loading} = useHttp();

    function onSelectionChange(selectedRowKeys, selected) {
        let filter = selectedRows.concat(selected.filter((item) => selectedRows.indexOf(item) < 0));
        console.log(filter);
        setSelectedRows(filter)
    }

    useEffect(() => {
        fetchData(data.page, data.pageSize);
    }, []);

    async function fetchData(page, pageSize) {
        const clients = await request(`/api/sms-service/getGroupedClients?page=${page}&pageSize=${pageSize}`, 'GET');
        setData({
            ...clients,
            content: clients.content.map((client, index) => ({...client, key: index + (page * pageSize)}))
        })
    }

    async function onPaginationChange({current, pageSize}) {
        await fetchData(current, pageSize)
    }

    const rowSelection = {
        selectedRowKeys: selectedRows.map(i => i.key),
        checkStrictly: true,
        onChange: onSelectionChange,
        selections: [
            {
                key: 'select_all',
                text: 'Выбрать всё',
                onSelect: changableRowKeys => {
                    console.log('select all', changableRowKeys)
                },
            },
            {
                key: 'deselect_all',
                text: 'Убрать всё',
                onSelect: changableRowKeys => {
                    setSelectedRows([])
                },
            },
            // Table.SELECTION_ALL,
            // Table.SELECTION_INVERT,
            // Table.SELECTION_NONE,
        ],
    };

    return (
        <>
            <Table
                columns={clientColumns}
                dataSource={data.content}
                loading={loading}
                rowSelection={rowSelection}
                onChange={onPaginationChange}
                pagination={{
                    page: data.page,
                    pageSize: data.pageSize,
                    total: data.totalElements
                }}
                selection={{key: 'phone'}}
            />
        </>
    )
};