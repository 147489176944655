import React from "react";
import {Button, Drawer, Modal} from "antd";

const FormWrapper = ({children, type, visible, drawerWidth, onClose, onDelete, isUpdate, formId}) => {
    if (type === 'Drawer') {
        return (
            <Drawer
                className={drawerWidth ? "" : "default-dialog-container"}
                width={drawerWidth ? drawerWidth : 'auto'}
                open={visible}
                onClose={() => onClose(true, isUpdate)}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <Button onClick={() => onClose(true, isUpdate)} style={{marginRight: 8}}>
                            Отмена
                        </Button>
                        {isUpdate && (
                            <Button onClick={onDelete} style={{marginRight: 8}}>
                                Удалить
                            </Button>
                        )}
                        <Button type="primary" htmlType="submit" form={formId || 'form'}>
                            Сохранить
                        </Button>
                    </div>
                }
            >
                {children}
            </Drawer>
        )
    } else {
        return (
            <Modal
                open={visible}
                onCancel={() => onClose(true, isUpdate)}
                maskClosable={false}
                footer={[
                    <Button key="close" onClick={() => onClose(true, isUpdate)}>
                        Отмена
                    </Button>,
                    isUpdate && <Button onClick={onDelete}>Удалить</Button>,
                    <Button key="submit" type="primary" htmlType={"submit"} form={formId || "form"}>
                        Сохранить
                    </Button>,
                ]}
            >
                {children}
            </Modal>
        )
    }
};
export default FormWrapper