import React from "react";
import {Button, Input} from "antd";
import {SyncOutlined} from "@ant-design/icons";

const {Search} = Input;

export default ({title = 'Таблица', onClick, setSearch, loading, hideCreate, refresh}) => {
    const style = {
        display: 'flex',
        width: '100%',
        alignItems: 'baseline'
    };
    return (
        <div style={{...style}}>
            <div style={{flexGrow: 1}}>
                {title}
            </div>
            <Button
                id={'refreshTableButton'}
                style={{alignSelf: 'center'}}
                type={'primary'}
                onClick={refresh}
                icon={<SyncOutlined/>}
            />
            <Search
                onSearch={setSearch}
                disabled={loading}
                placeholder={"Поиск"}
                style={{width: 150, margin: "0 10px"}}
            />
            {!hideCreate && (
                <Button onClick={() => onClick()} disabled={loading}>
                    Создать
                </Button>
            )}
        </div>
    )
};