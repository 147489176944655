export const ACTIONS = {
    SET_UPDATE: 'set-update',
    TOGGLE_DRAWER: 'toggle-drawer',
    REFRESH_DATA: 'refresh-data',
    SET_RECORD_ID: 'set-record-id',
    SET_FORM_VALUES: 'set-from-values',
    APPEND_OFFICE_FORM: 'append-office-form',
    SET_TABLE_DATA: 'set-table-data',
    SET_PAGINATION: 'set-pagination',
    UPDATE_TOTAL_ELEMENTS: 'update-total-elements',
    SET_ORDER: 'set-order',
    SET_FILTER: 'set-filter',
    SET_SEARCH_VALUE: 'set-search-value',
};