import React from 'react';
import PropTypes from 'prop-types'
import FormWrapper from "./FormWrapper";
import FormRender from "./FormRender";

const DefaultForm = (props) => {
    const {
        form, formValues, formRenderer, formRef, formId,
        onFinish, onClose, onDelete, visible, isUpdate,
        drawerWidth, formWrapper, fetchData, onCreateCallback
    } = props;
    const validateMessages = {
        required: '${label} обязательное поле!',
        types: {
            email: '${label} не правильный e-mail!',
            number: '${label} не валидное число!',
        },
        number: {
            range: '${label} must be between ${min} and ${max}',
        },
    };
    return (
        <FormWrapper
            type={formWrapper}
            drawerWidth={drawerWidth}
            onClose={onClose}
            onDelete={onDelete}
            visible={visible}
            isUpdate={isUpdate}
            formId={formId}
        >
            <div style={{margin: '20px 0'}}>
                <FormRender
                    formRef={formRef}
                    form={form}
                    formValues={formValues}
                    onFinish={onFinish}
                    validateMessages={validateMessages}
                    formRenderer={formRenderer}
                    onClose={onClose}
                    fetchData={fetchData}
                    visible={visible}
                    formId={formId}
                    onCreateCallback={onCreateCallback}
                />
            </div>
        </FormWrapper>
    )
};

export default DefaultForm

function none() {
}

DefaultForm.defaultProps = {
    form: {},
    formValues: [],
    onFinish: none,
    onClose: none,
    onDelete: none,
    drawerWidth: 500,
    isUpdate: false,
    visible: false,
    formRenderer: null,
    formWrapper: 'Dialog'
};

DefaultForm.propTypes = {
    form: PropTypes.object,
    formValues: PropTypes.array,
    onFinish: PropTypes.func,
    onClose: PropTypes.func,
    onDelete: PropTypes.func,
    drawerWidth: PropTypes.number,
    isUpdate: PropTypes.bool,
    visible: PropTypes.bool,
    formRenderer: PropTypes.func,
    formWrapper: PropTypes.oneOf(['Drawer', 'Modal'])
};