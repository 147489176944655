import React from "react";
import {Col, Row} from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import DescriptionItem from "./DescriptionItem";

const Device = ({brand, model, type, device = {}, record}) => {
    return (
        <>
            <b>
                <p className="site-description-item-profile-p">
                    Устройство на приёме
                </p>
            </b>
            <Row>
                <Col span={10} xs={24} md={10}>
                    <DescriptionItem title={type.name || '-'}
                                     content={`${brand.name || '-'} ${model.name || '-'}`}/>
                </Col>
                <Col span={12} xs={24} md={10}>
                    <DescriptionItem title={'Серийный Номер'}
                                     content={<Paragraph style={{marginBottom: 0}}
                                                         copyable>{device ? device.serial : "-"}</Paragraph>}/>
                </Col>
            </Row>
            <Row>
                <Col span={10} xs={24} md={10}>
                    <DescriptionItem title={'Пин'} content={`${record.devicePassword}`}/>
                </Col>
                <Col span={12} xs={24} md={12}>
                    <DescriptionItem title={'Внешний вид'} content={record.appearance ? record.appearance : ''}/>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <DescriptionItem title={'Описание проблемы'} content={record.problem ? record.problem : ''}/>
                </Col>
            </Row>
        </>
    )
};

export default Device