import React, {useState} from 'react'
import {Tabs} from "antd";
import TransactionHistory from "../components/Finances/TransactionHistory";
import CashBox from "../components/Finances/Cashbox";
const Finances = () => {
    const [tab, setTab] = useState("1");
    return (
        <>
            <div className="card-container">
                <Tabs
                    type={"card"}
                    onChange={setTab}
                    activeKey={tab}
                    items={[{
                        label: "Касса",
                        key: "1",
                        children: <CashBox tab={tab}/>
                    }, {
                        label: "История Транзакций",
                        key: "2",
                        children: <TransactionHistory tab={tab}/>
                    }]}
                />
            </div>
        </>
    )
};

export default Finances



